
export default {
	data() {
		return {
			// showDisclaimer: false,
		}
	},
	methods: {
		showDisclaimer() {
			this.$emit("show-disclaimer")
		},
	},
}
