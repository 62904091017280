export default ({ app, $auth, store, route }, inject) => {
	$auth.onRedirect((to, from) => {
		console.log("onRedirect", route)
		if (to.startsWith("#")) {
			return app.localePath({
				name: app.getRouteBaseName(route),
				params: route.params,
				query: route.query,
				hash: to,
			})
		}
		return app.localePath(to)
	})
}
