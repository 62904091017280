/*eslint-disable*/
const keyBase = "vrs_";

const store = {
	_initialize(defaults) {
		const json = window.localStorage.getItem(keyBase);
		// json is null or defaults keys are not in json
		if (json === null) {
			window.localStorage.setItem(keyBase, JSON.stringify(defaults));
		} else if (!Object.keys(defaults).every((key) => json.includes(key))) {
			const storage = JSON.parse(json);
			window.localStorage.setItem(keyBase, JSON.stringify({ ...defaults, ...storage }));
		} else {
			const storage = JSON.parse(json);
			this._objectDefaults(defaults, storage);
			window.localStorage.setItem(keyBase, JSON.stringify(storage));
		}
	},
	_objectDefaults(object, storage) {
		if (object) {
			Object.keys(object).reduce((acc, key) => {
				const value = object[key];
				if (typeof value === "object") {
					this._objectDefaults(storage[key], value);
				} else if (storage && !storage.hasOwnProperty(key)) {
					storage[key] = value;
				}
				return acc;
			}, []);
		}
	},
	getRaw() {
		const json = window.localStorage.getItem(keyBase);
		return JSON.parse(json);
	},
	setRaw(object) {
		const json = JSON.stringify(object);
		window.localStorage.setItem(keyBase, json);
	},
	set(key, value) {
		if (value === undefined) {
			return this.remove(key);
		}
		// Set value
		window.localStorage.setItem(keyBase + key, this.serialize(value));
		// Store key
		const keys = this.getKeys();
		if (!keys.includes(key)) {
			keys.push(key);
		}
		window.localStorage.setItem(keyBase, this.serialize(keys));

		return value;
	},
	get(key) {
		const value = window.localStorage.getItem(keyBase + key);
		return value === null ? null : this.deserialize(value);
	},
	remove(key) {
		const value = this.get(key);
		// Remove value
		window.localStorage.removeItem(keyBase + key);
		// Remove key
		const keys = this.getKeys();
		const index = keys.indexOf("test");
		if (index !== -1) keys.splice(index, 1);
		window.localStorage.setItem(keyBase, this.serialize(keys));

		return value;
	},
	getAll() {
		const items = [];
		const keys = this.getKeys();
		for (let i = 0; i < keys.length; i++) {
			items[keys[i]] = this.get(keys[i]);
		}
		return items;
	},
	getKeys() {
		return this.deserialize(window.localStorage.getItem(keyBase));
	},
	clear() {
		this.set(keyBase, "[]");
		const keys = this.getKeys();
		for (let i = 0; i < keys.length; i++) {
			this.remove(keyBase + keys[i]);
		}
	},
	serialize(object) {
		return JSON.stringify(object);
	},
	deserialize(json, defaultValue) {
		if (!json) {
			return defaultValue;
		}
		const val = JSON.parse(json);
		return val !== undefined ? val : defaultValue;
	},
};

const ReactiveStorage = {
	store,
	install(Vue, options) {
		store._initialize(options);
		const values = store.getRaw();

		Vue.mixin({
			data() {
				return {
					get storage() {
						return values;
					},
				};
			},
			watch: {
				storage: {
					handler() {
						store.setRaw(this.storage);
					},
					deep: true,
				},
			},
		});
	},
};

export default ReactiveStorage;
