
import field from "../mixins/field";
export default {
	mixins: [field],
	props: {
		noFuture: {
			type: Boolean,
			default: false,
		},

		hours: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: "date",
		},
	},
	data() {
		return {
			menu: false,
		};
	},
	computed: {
		localMax() {
			if (this.max) {
				return this.max;
			}

			if (this.noFuture) {
				return new Date().toISOString();
			}

			return undefined;
		},
	},
};
