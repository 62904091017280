
export default {
	props: {
		src: {
			type: String,
			required: true,
		},
		width: {
			type: [Number, String],
			default: "100%",
		},
		maxWidth: {
			type: [Number, String],
			default: "100%",
		},

		aspectRatio: {
			type: [Number, String],
			default: 16 / 9,
		},
	},
	computed: {
		videoId() {
			// using regex, extract video id from urls like:
			// https://www.youtube.com/watch?v=9bZkp7q19f0
			// https://youtu.be/9bZkp7q19f0
			// https://www.youtube.com/embed/9bZkp7q19f0
			// https://www.youtube.com/embed/9bZkp7q19f0?rel=0
			// https://www.youtube.com/embed/9bZkp7q19f0?rel=0&autoplay=1
			// https://www.youtube.com/embed/9bZkp7q19f0?rel=0&autoplay=1&start=10

			const regex = /(?:v=|be\/|\/embed\/)([a-zA-Z0-9_-]+)/
			const match = this.src?.match(regex)
			return match ? match[1] : null
		},
		calculatedHeight() {
			// the width could be string, number. and could have px or % at the end

			const width = this.width.toString().replace(/[^0-9]/g, "")
			// extract px or %
			const unit = this.width.toString().replace(/[0-9]/g, "")

			return width / this.aspectRatio + unit
		},
		calculatedMaxHeight() {
			// the width could be string, number. and could have px or % at the end

			const width = this.maxWidth.toString().replace(/[^0-9]/g, "")
			// extract px or %
			const unit = this.maxWidth.toString().replace(/[0-9]/g, "")

			return width / this.aspectRatio + unit
		},
	},
}
