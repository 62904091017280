
import { mapState, mapGetters, mapActions } from "vuex"
import NoWishlistItems from "./NoWishlistItems.vue"
export default {
	components: { NoWishlistItems },
	data() {
		return {
			...mapState,
			drawerModel: false,
		}
	},
	computed: {
		...mapGetters({ wishlistItems: "wishlist/wishlistItems" }),
	},

	mounted() {
		this.$nuxt.$on("wishlist.show", this.open)
		this.$nuxt.$on("wishlist.hide", this.close)
		this.$nuxt.$on("wishlist.add", this.add)
		this.$nuxt.$on("wishlist.remove", this.remove)
		this.$nuxt.$on("wishlist.update", this.update)
	},
	methods: {
		...mapActions({ add: "wishlist/add", remove: "wishlist/remove", get: "wishlist/get" }),
		open() {
			this.drawerModel = true
		},
		close() {
			this.drawerModel = false
		},
	},
}
