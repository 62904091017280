
export default {
	name: "CrudDrawer",

	props: {
		value: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: "New",
		},
		loading: {
			type: Boolean,
			default: false,
		},
		width: {
			type: [Number, String],
			default: 800,
		},
		isSaving: {
			type: Boolean,
			default: false,
		},
		error: {
			type: Boolean,
			default: false,
		},
		model: {
			type: String,
			default: null,
		},
		id: {
			type: [String, Number],
			default: null,
		},
	},
	data() {
		return {}
	},
	computed: {
		localValue: {
			get() {
				return this.value
			},
			set(v) {
				this.$emit("input", v)
			},
		},
		$form() {
			return this.$refs.form
		},
	},
	methods: {
		close() {
			this.localValue = false
			this.reset()
			this.$emit("close")
		},
		handleStickyChange(isSticky) {
			console.log("isSticky", isSticky)
		},
		reset() {
			this.$form.reset()
		},
		validate() {
			return this.$form.validate()
		},
	},
}
