
// create component that convert date string to days, hours, minutes, seconds and starts counting down on mounted
export default {
	props: {
		date: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0,
			timer: null,
		}
	},
	computed: {
		time() {
			return "" // this.days + "d " + this.hours + "h " + this.minutes + "m " + this.seconds + "s";
		},
	},
	mounted() {
		this.startTimer()
	},
	beforeDestroy() {
		clearInterval(this.timer)
	},

	methods: {
		startTimer() {
			this.timer = setInterval(() => {
				this.countDown()
			}, 1000)
		},
		formatTimeUnit(value) {
			return String(value).padStart(2, "0")
		},
		countDown() {
			const date = new Date(this.date).getTime()
			const now = new Date().getTime()
			const distance = date - now
			this.days = Math.floor(distance / (1000 * 60 * 60 * 24))
			this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
			this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
			this.seconds = Math.floor((distance % (1000 * 60)) / 1000)
			if (distance < 0) {
				clearInterval(this.timer)
				this.days = 0
				this.hours = 0
				this.minutes = 0
				this.seconds = 0
			}
		},
	},
}
