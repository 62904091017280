import Vue from "vue"
import Router from "vue-router"
import cloneDeep from "clone-deep"
import locales from "~/config/Locales.js"

Vue.use(Router)

function localizeRoutes(routes, locale) {
	const localizedRoutes = cloneDeep(routes).map((route) => {
		const path = route.path.replace(/^\//, "")
		route.path = "/" + locale + (path ? "/" + path : "")
		route.name = route.name + "___" + locale
		if (route.children) {
			route.children = localizeRoutes(route.children, locale)
		}
		return route
	})

	return localizedRoutes
}

export async function createRouter(ssrContext, createDefaultRouter, routerOptions, $config, store) {
	let newRoutes = []

	const routes = await import(`~/routes/app.js`).then(m => m.default || m)

	const codes = locales.map(({ code }) => code)

	codes.forEach((code) => {
		const localizedRoutes = localizeRoutes(routes, code)

		newRoutes = [...newRoutes, ...localizedRoutes]
	})

	newRoutes.push({
		name: "health",
		path: "/_health",
		component: () => import(/* webpackChunkName: "health" */ "~/pages/HealthCheck.vue").then(m => m.default || m),
	})

	return new Router({
		mode: "history",
		routes: newRoutes,
	})
}
