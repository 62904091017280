
export default {
	props: {
		item: {
			type: Array,
			default: () => [],
		},
	},

	computed: {
		stock() {
			if (this.isTypeBundle) {
				return this.item.stock
			}
			if (this.isTypeAlternative || this.isTypeSimple) {
				return this.item.variance?.stock
			}

			return false
		},
		priceAltrnative() {
			const formatter = new Intl.NumberFormat(this.$i18n.localeProperties.iso, {
				// style: "number",
				minimumFractionDigits: 0,
				maximumFractionDigits: 2,
			})
			if (this.item.maxPrice.value === this.item.minPrice.value) {
				const value = formatter.format(this.item.minPrice.value)
				return value
			} else {
				return `${formatter.format(this.item.minPrice.value)} - ${formatter.format(this.item.maxPrice.value)}`
			}
		},
		samePrice() {
			return this.item.maxPrice.value === this.item.minPrice.value
		},
		isPreOrder() {
			return !!this.stock && this.stock.isPreOrder
		},
		hasStock() {
			return this.item.hasStock
		},
		hasOffer() {
			return this.item.hasOffer
		},
		hasVariance() {
			return !!this.item?.variance && this.item?.variance?.name
		},

		hasDiscount() {
			if (this.hasStock) {
				return this.stock?.isOffer
			}

			return false
		},
		discount() {
			if (this.hasDiscount) {
				return 100 - Math.round((this.stock.price.value / this.stock.priceBeforeOffer.value) * 100)
			}
			return 0
		},
		discountAmount() {
			if (this.hasDiscount) {
				const formatter = new Intl.NumberFormat(this.$i18n.localeProperties.iso, {
					// style: "number",
					minimumFractionDigits: 0,
					maximumFractionDigits: 2,
				})
				const value = formatter.format(this.stock.priceBeforeOffer?.value - this.stock.price.value)
				return {
					value,
					currency: this.stock.price.currency,
				}
			}
			return 0
		},

		discountAmountAltrnative() {
			if (this.hasDiscount) {
				return {
					value: this.item.maxPrice.value === this.item.minPrice.value ? this.item.minPriceBeforeOffer?.value - this.item.minPrice.value : `${this.item.minPriceBeforeOffer.value} - ${this.item.maxPriceBeforeOffer.value}`,
					currency: this.minPrice.currency,
				}
			}
			return 0
		},
		discountExpiresIn() {
			if (this.hasDiscount) {
				return this.stock.unPublishedAt
			}
			return 0
		},

		price() {
			if (this.hasStock) {
				const formatter = new Intl.NumberFormat(this.$i18n.localeProperties.iso, {
					// style: "number",
					minimumFractionDigits: 0,
					maximumFractionDigits: 2,
				})
				const value = formatter.format(this.stock.price)
				return value
			}
			return null
		},

		symbol() {
			// to small latter
			const symbol = this.item.minPrice?.currency ?? 'jod'
			return symbol.toLowerCase()
		},

		priceObject() {
			if (this.hasStock) {
				const symbol = this.stock?.price?.currency
				const formatter = new Intl.NumberFormat(this.$i18n.localeProperties.iso, {
					// style: "number",
					minimumFractionDigits: 0,
					maximumFractionDigits: 2,
				})
				const value = formatter.format(this.stock?.price?.value)
				return { symbol, value }
			}

			return null
		},

		priceBeforeOffer() {
			return this.stock.priceBeforeOffer.value
		},

		priceBeforeOfferAltrnative() {
			if (this.item.maxPrice.value === this.item.minPrice.value) {
				return this.item.minPriceBeforeOffer.value
			}
			return `${this.item.minPriceBeforeOffer.value} - ${this.item.maxPriceBeforeOffer.value}`
		},
		isTypeSimple() {
			return this.item.type === "simple"
		},
		isTypeBundle() {
			return this.item.type === "bundle"
		},
		isTypeAlternative() {
			return this.item.type === "alternative"
		},
		computedLoading() {
			return this.loading
		},
	},
}
