
export default {
	props: {
		model: {
			type: String,
			required: true,
		},
		id: {
			type: [String, Number],
			// required: true,
			default: "",
		},
	},
	data() {
		return {
			drawerModel: false,
			log: [],
		}
	},
	async fetch() {
		if (!this.drawerModel) { return }

		await this.$axios.$get(`/v1/admin/activity-log/model/${this.model}/${this.id}`).then((resp) => {
			this.log = resp
		})
	},
	methods: {
		open() {
			this.drawerModel = true
			this.$fetch()
		},
		close() {
			this.drawerModel = false
			this.log = []
		},
	},
}
