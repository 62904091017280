
import field from "../mixins/field.js";
import { mdiEye, mdiEyeOff, mdiClose, mdiCheck } from "@mdi/js";
export default {
	name: "Password",
	mixins: [field],
	props: {
		value: {
			type: [String],
			default: null,
		},
		noStrength: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showPassword: false,
			userInputs: ["1", "2", "3", "4", "5"],
			showTooltip: false,
			mdiEye,
			mdiEyeOff,
			mdiClose,
			mdiCheck,
			trimSides: 0,
		};
	},
	computed: {
		localPassword: {
			get() {
				return this.value;
			},
			set(v) {
				this.$emit("input", v);
			},
		},
		hasLowerCase() {
			return this.localPassword && this.localPassword.match(/([a-z].*)|(.*[a-z])/);
		},
		hasUpperCase() {
			return this.localPassword && this.localPassword.match(/(.*[A-Z])|([A-Z].*)/);
		},
		hasNumber() {
			return this.localPassword && this.localPassword.match(/\d/);
		},
		hasSpecial() {
			return this.localPassword && this.localPassword.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/);
		},
		hasLength() {
			return this.localPassword && this.localPassword.length > 7;
		},
		progress() {
			// return Math.min(100, this.value?.length * 10) || 0;
			let strength = 0;

			if (this.hasLowerCase) {
				strength += 20;
			}
			if (this.hasUpperCase) {
				strength += 20;
			}
			if (this.hasNumber) {
				strength += 20;
			}
			if (this.hasSpecial) {
				strength += 20;
			}
			if (this.hasLength) {
				strength += 20;
			}
			return strength;
		},
		hasAnyError() {
			if (this.noStrength) return false;
			return (
				(this.hasLowerCase && this.hasUpperCase && this.hasNumber && this.hasSpecial && this.hasLength) ||
				this.$t("vc.password-requirements")
			);
		},
		color() {
			return ["error", "error", "warning", "warning", "success"][Math.floor(this.progress / 20) - 1];
		},
		calculatedRules() {
			const r = [...this.rules];
			if (!this.noStrength) {
				r.push(this.hasAnyError);
			}
			return r;
		},
	},
	created() {},
	mounted() {},
	methods: {
		focusHandler() {
			if (this.noStrength) return;

			this.showTooltip = true;
		},
		blurHandler() {
			if (this.noStrength) return;

			this.showTooltip = false;
		},
	},
};
