
export default {
	data() {
		return {
			form: {
				code: null,
				userId: null,
			},
			loading: false,
		}
	},
	methods: {
		submit() {
			this.form.userId = this.$store.state.authExtend.userId
			if (!this.$refs.otp.validate()) {
				this.scrollToError()
				return
			}
			this.loading = true
			this.$store
				.dispatch("authExtend/verifyCode", this.form)
				.then(() => {
					this.$nuxt.$emit("auth.show", "verify-reset-password-code")
				})
				.catch((e) => {
					this.genericErrorHandler(e, this.$refs.otp)
				})
				.finally(() => {
					this.loading = false
				})
		},
	},
}
