export default function ({ app, $vuetify, i18n }) {
	// onBeforeLanguageSwitch called right before setting a new locale
	// app.i18n.onBeforeLanguageSwitch = (oldLocale, newLocale, isInitialSetup, context) => {
	// 	console.log("onBeforeLanguageSwitch", oldLocale);
	// 	app.nuxt.defaultTransition.name = "fade-transition";
	// };
	// // onLanguageSwitched called right after a new locale has been set
	app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
		// console.log("onLanguageSwitched", newLocale);
		// const locale = i18n.locales.find((locale) => locale.code === newLocale);
		$vuetify.rtl = i18n.localeProperties.dir === "rtl"
		app.nuxt.defaultTransition.name = "page"
	}

	app.nuxt.defaultTransition.beforeEnter = async (html) => {
		// console.log("beforeEnter", html);
		await app.i18n.finalizePendingLocaleChange()
		// setTimeout(() => {
		// 	app.i18n.finalizePendingLocaleChange();
		// }, 150);
	}

	// // Optional: wait for locale before scrolling for a smoother transition
	app.router.options.scrollBehavior = async (to, from, savedPosition) => {
		// Make sure the route has changed
		if (to.name !== from.name) {
			app.nuxt.defaultTransition.name = "fade-transition"
			await app.i18n.waitForPendingLocaleChange()
			app.nuxt.defaultTransition.name = "page"
		}
		return savedPosition || { x: 0, y: 0 }
	}
	app.vuetify.framework.rtl = true
}
