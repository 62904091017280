export const state = () => ({
	logo: require("~/assets/images/logo-light.png"),
	menu: [
		{
			label: "تصفح",
			children: [],
			to: "/c/mobiles-and-tablets",
		},
		{
			label: "Ramadan offers",
			// children: []
		},
		{
			label: "Auctions",
			// children: []
		},
		{
			label: "Maintenance",
			// children: []
		},
		{
			label: "On Sale",
			// children: []
		},
		{
			label: "New Arrivals",
			// children: []
		},
		{
			label: "Most Ordered",
			// children: []
		},
	],
})

export const mutations = {}
