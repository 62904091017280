export default ({ app }, inject) => {
	const globalOptions = {
		right: true, // default
		bottom: true, // default
		color: "info", // default
		icon: "mdi-information", // default
		iconColor: "", // default
		classes: ["body-2"],
		timeout: 3000, // default
		dismissable: true, // default
		multiLine: false, // default
		vertical: false, // default
		queueable: false, // default
		showClose: false, // default
		closeText: "", // default
		closeIcon: "mdi-close", // default
		closeColor: "", // default
		slot: [], // default
		shorts: {
			success: {
				color: "success",
				icon: "mdi-check",
			},
			error: {
				color: "error",
				icon: "mdi-alert-circle",
				timeout: 0,
				showClose: true,
			},
			warning: {
				color: "warning",
				icon: "mdi-alert",
				timeout: 10000,
				showClose: true,
			},
			info: {
				color: "info",
				icon: "mdi-information",
				timeout: 4000,
				showClose: true,
			},
			notification: {
				color: "primary",
				icon: "mdi-bell",
				timeout: 0,
				showClose: true,
			},
			debug: {
				color: "grey",
				icon: "mdi-bug",
				timeout: 0,
				showClose: true,
			},
		},
		title: null,
	}

	const queue = []

	function show(message, options = {}) {
		options.message = message
		window.$nuxt.$emit("toast.add", { ...globalOptions, ...options })
	}

	function shorts(options) {
		const colors = ["success", "info", "error", "warning"]
		const methods = {}

		colors.forEach((color) => {
			methods[color] = (message, options) => show(message, { color, ...options })
		})

		if (options.shorts) {
			for (const key in options.shorts) {
				const localOptions = options.shorts[key]
				methods[key] = (message, options) => {
					return show(message, { ...localOptions, ...options })
				}
			}
		}

		return methods
	}

	function clearQueue() {
		return queue.splice(0, queue.length)
	}

	const toast = { globalOptions, clearQueue }
	const methods = shorts(globalOptions)
	for (const key in methods) {
		toast[key] = methods[key]
	}
	inject("toast", toast)
}
