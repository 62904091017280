import Vue from "vue"
// import ReactiveStorage from "./ReactiveLocalStorage";
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber"
import responsive from "~/mixins/responsive"
import config from "~/mixins/config"
import errorHandler from "~/mixins/errorHandler"
import general from "~/mixins/general"
let app = null
Vue.filter("date", (value) => {
	return app
		.$dayjs(value)
		.locale(app.i18n.locale || "ar")
		.format("YYYY-MM-DD")
})
Vue.filter("dateTime", (value) => {
	if (!value) { return "" }
	return app
		.$dayjs(value)
		.locale(app.i18n.locale || "ar")
		.format("YYYY-MM-DD h:mm A")
})
Vue.filter("time", (value) => {
	return app
		.$dayjs(value)
		.locale(app.i18n.locale || "ar")
		.format("h:mm A")
})
Vue.filter("niceDate", (value) => {
	return app
		.$dayjs(value)
		.locale(app.i18n.locale || "ar")
		.format("ll")
})
Vue.filter("niceDateTime", (value) => {
	return app
		.$dayjs(value)
		.locale(app.i18n.locale || "ar")
		.format("llll")
})

Vue.filter("smartDate", (value) => {
	const now = app.$dayjs()
	const date = app.$dayjs(value)
	const diff = now.diff(date, "days")
	if (diff === 0) {
		return date.locale(app.i18n.locale || "ar").format("h:mm A")
	} else if (diff === 1) {
		return app.i18n.t("yesterday")
	} else if (diff < 7) {
		return date.locale(app.i18n.locale || "ar").format("dddd")
	} else {
		return date.locale(app.i18n.locale || "ar").format("ll")
	}
})
Vue.filter("smartDateTime", (value) => {
	const now = app.$dayjs()
	const date = app.$dayjs(value)
	const diff = now.diff(date, "days")
	if (diff === 0) {
		return date.locale(app.i18n.locale || "ar").format("h:mm A")
	} else if (diff === 1) {
		return app.i18n.t("yesterday")
	} else if (diff < 7) {
		return date.locale(app.i18n.locale || "ar").format("dddd h:mm A")
	} else {
		return date.locale(app.i18n.locale || "ar").format("lll")
	}
})

Vue.filter("fromNow", (value) => {
	return app
		.$dayjs(value)
		.locale(app.i18n.locale || "ar")
		.fromNow()
})

Vue.filter("money", (value, currency = "USD", $round = 2) => {
	if (value === undefined || value === null) { return "" }

	if (typeof value === "object") {
		currency = value.currency
		value = value.value
	}

	const formatter = new Intl.NumberFormat(app.i18n.localeProperties.iso, {
		style: "currency",
		currency,
		minimumFractionDigits: 0,
		maximumFractionDigits: $round,
	})
	// console.log('formatter', formatter)
	return formatter.format(value)
})
Vue.filter("moneyObject", (value, currency = "USD", $round = 2) => {

})
Vue.filter("phone", (value) => {
	if (!value) { return "" }

	if (typeof value === "object") {
		value = `+${value.code}${value.number}`
		value = value.replace(/\++/g, "+")
	}
	const phoneUtil = PhoneNumberUtil.getInstance()
	const number = phoneUtil.parseAndKeepRawInput(value, "ZZ")
	return phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL) // value.replace(/\D+/g, "").replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 $2 $3$4");
})
Vue.filter("progress", (value) => {
	return `${Math.round(value * 10000) / 100}`
})

Vue.filter("trans", (value) => {
	if (typeof value === "object") {
		return value[app.i18n.locale] || value[app.i18n.fallbackLocale]
	} else {
		return value
	}
})

Vue.filter("attrValue", (option, type) => {
	switch (type) {
		case "number":
			return (option.number)
		case "color":
			return (option.name)
		case "brand":
			return (option.text)
		case "checkbox":
			return (option.name)
		case "text":
			return option.value || option.name
		default:
			return option.value
	}
})

Vue.filter("initials", (value) => {
	// return initials from full name
	if (!value) { return "" }

	const names = value.split(" ")
	let initials = names[0].substring(0, 1).toUpperCase()
	if (names.length > 1) {
		initials += names[names.length - 1].substring(0, 1).toUpperCase()
	}
	return initials
})
Vue.mixin(responsive)
Vue.mixin(config)
Vue.mixin(errorHandler)
Vue.mixin(general)
export default (ctx, inject) => {
	app = ctx.app
}
