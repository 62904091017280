
export default {
	props: {
		configs: {
			type: Object,
			default: () => ({}),
		},
		items: {
			type: Array,
			default: () => [],
		},
		columns: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		parsedColumns() {
			return [...this.columns, { text: "", value: "actions" }];
		},
	},
};
