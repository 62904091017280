
import field from "../mixins/field";
export default {
	mixins: [field],
	computed: {
		parsedItems() {
			return this.localItems
				.filter((item) => item.selectable !== false)
				.map((item) => ({ value: item.value, text: item.text || this.$t(item.key), color: item.color }));
		},
	},
};
