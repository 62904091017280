import { mapGetters, mapActions } from "vuex"
export default {
	methods: {
		...mapActions({
			login: "authx/login",
			logout: "authx/logout",
		}),
	},
	computed: {
		...mapGetters({
			isLoggedIn: "auth/isLoggedIn",
		}),
	},
}
