// search in elements and its $children with matching name and return element
function findElByName(elements, name) {
	return (
		elements.find(el => el.$attrs.name === name) ||
		elements.reduce((acc, el) => {
			return acc || findElByName(el.$children, name)
		}, null)
	)
}
export default {
	data() {
		return {}
	},
	methods: {
		genericErrorHandler(e, ref) {
			// console.log(e.response);
			if (e.response) {
				const { message } = e.response.data
				if (e.response.status === 422) {
					// const msg = "- " + Object.values(e.response.data.data).join("<br>- ");
					if (process.client) {
						const errors = e.response.data.details
						if (ref) {
							for (const key in e.response.data.details) {
								const el = findElByName(ref.$children, key)
								if (el) {
									el.error = true
									el.errorMessages = errors[key]

									el.onInput = (e) => {
										el.error = !!el.errorMessages.length
										el.errorMessages = []

										el.internalValue = e.target.value
										el.badInput = e.target.validity && e.target.validity.badInput
									}
								}
							}
						}
						this.$toast.error(Object.values(e.response.data.errors), { title: message, timeout: 0, showClose: true })
					} else {
						console.error(e.response.data.errors)
					}
				} else if (e.response.status === 404) {
					// this.$router.push("/404");
				} else if (e.response.status === 500) {
					if (process.client) {
						this.$toast.error(message, { title: "500 Server Error Occurred", timeout: 0, showClose: true })
					} else {
						console.error(message)
					}
				} else if (process.client) {
					this.$toast.error(message, { title: `${e.response.status} Error Occurred`, timeout: 0, showClose: true })
				} else {
					console.error(message)
				}
			}
		},
		pageErrorHandler(e) {
			// this.$logError('Page Error Handler:' + e);
			const status = e?.response?.status
			// check if axios cancel event
			if (this.$axios.isCancel(e)) {
				// Ignore if request was cancelled
				return
			}
			if (status === 404) {
				//   this.statusCode = "404";

				return this.$nuxt.error({
					statusCode: 404,

					message: "Not found",
				})
			} else {
				return this.$nuxt.error({
					statusCode: 500,
					source: "api",
					message: "Data Server Error",
				})
			}
			// return Promise.reject(e);
		},
	},
}
