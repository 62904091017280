export default function ({ $axios, redirect, $config, store, req, i18n, auth }) {
	$axios.$getOnce = function (url, config) {
		const fullPath = $axios.getUri({ url, params: config?.params })

		if (store.getters["cache/isPromiseExist"](fullPath)) {
			// console.log("cache hit", store.getters["cache/getPromise"](fullPath));
			return store.getters["cache/getPromise"](fullPath)
		}
		if (store.getters["cache/isKeyExist"](fullPath)) {
			// console.log("cache hit", Promise.resolve(store.getters["cache/getKey"](fullPath)));
			return new Promise((resolve) => {
				resolve(store.getters["cache/getKey"](fullPath).value)
			})
		} else {
			const promise = this.$get(url, { ...config, headers: { ...config?.headers } }).then((response) => {
				store.dispatch("cache/insertKey", { key: fullPath, value: response })
				return response
			})

			store.dispatch("cache/insertPromise", { key: fullPath, value: promise })
			return promise
		}
	}

	$axios.onRequest((apiReq) => {
		if (!apiReq.headers.common) { apiReq.headers.common = {} }
		if (i18n.locale) {
			apiReq.headers.common.Language = i18n.locale
		}

		if (!store.state.auth.loggedIn && store.state.visitorId) {
			apiReq.headers.common['Visitor-Id'] = store.state.visitorId
		}

		apiReq.headers.common.Currency = store.state.currency
		// for arabic letters
		apiReq.url = encodeURI(apiReq.url)
	})

	$axios.onRequestError((e) => {
		// check if error type ExpiredAuthSessionError

		console.log("onRequestError ", e)
	})

	$axios.onError((e) => {
		console.log("onError ", e, e?.response?.data) //   // TODO: this shouldn't be here or at least wrap with process.server
	})

	$axios.onResponse((resp) => { })
	// $axios.onResponseError((error) => {

	// });
}
