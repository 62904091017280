export default {
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.xs
		},
		isTablet() {
			return this.$vuetify.breakpoint.sm
		},
		isDesktop() {
			return this.$vuetify.breakpoint.mdAndUp
		},
		isRTL() {
			return this.$vuetify.rtl
		},
		isThemeDark() {
			return this.$vuetify.theme.isDark
		},
		theme() {
			return this.$vuetify.theme.isDark ? "dark" : "light"
		},
	},
}
