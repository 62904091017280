
export default {
	data() {
		return {
			form: {
				phone: {
					code: "962",
					number: null,
					iso: "JO",
				},
			},
			apiError: [],
			isLoading: false,
		}
	},
	methods: {
		submit() {
			if (!this.$refs.phone.validate()) {
				this.scrollToError()
				return
			}
			this.isLoading = true
			this.$store
				.dispatch("authExtend/sendResetPasswordPhone", this.form)
				.then(() => {
					this.$toast.success(this.$t("auth.reset-password-phone-sent"))
					this.$nuxt.$emit("auth.show", "verify-code")
				})
				.catch((e) => {
					if (e.response.status === 422) {
						this.apiError = e.response.data.errors.phone.number[0]
					} else {
						this.genericErrorHandler(e, this.$refs.phone)
					}
				})
				.finally(() => {
					this.isLoading = false
				})
		},
	},
}
