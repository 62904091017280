
export default {
	data() {
		return {
			countries: [],
			form: {
				password: null,
				phone: { code: "962", number: null, iso: "jo" },
				firstName: null,
				lastName: null,
				email: null,
				confirmation: null,
			},
			isLoading: false,
		}
	},
	computed: {
		agreementLink() {
			return this.$store.state.agreementLink
		},
	},
	methods: {
		submit() {
			if (!this.$refs.register.validate()) {
				this.scrollToError(this.$refs.login)
				return
			}
			this.isLoading = true
			this.$axios
				.$post(`/v1/auth/register`, this.form)
				.then((resp) => {
					console.log("login then")
					console.log("RESP", resp)
					try {
						return this.$store
							.dispatch("authExtend/login", this.form)
							.then(() => {
								this.$nuxt.$emit("auth.success")
							})
							.catch((e) => {
								this.genericErrorHandler(e, this.$refs.login)
							})
					} catch (error) {
						console.log("Catch ERROR 1", error)
					}
				})
				.catch((e) => {
					console.log("login catch")
					console.log("CATCH ERROR 2", e)
					this.genericErrorHandler(e, this.$refs.register)
				})
				.finally(() => {
					this.isLoading = false
				})
		},
	},
}
