import { generateVisitorId } from "~/modules/GenerateVisitorId.js"
export const state = () => ({
	categories: [],
	collectionErrors: {},
	initialDataLoaded: false,
	currency: "JOD",
	// current website without protocol
})

export const getters = {
	languages(state) {
		return state.languages
	},
	isAnyCollectionError(state) {
		return Object.keys(state.collectionErrors).length > 0
	},
	isInitialDataLoaded(state) {
		return state.initialDataLoaded
	},
	flattenedCategories(state) {
		const flatten = (arr) => {
			return arr.reduce((acc, item) => {
				acc.push(item)
				if (item.children && item.children.length) {
					acc.push(...flatten(item.children))
				}
				return acc
			}, [])
		}
		return flatten(state.categories)
	},
	getCategoryBySlug: state => (slug) => {
		const flatten = (arr) => {
			return arr.reduce((acc, item) => {
				acc.push(item)
				if (item.children && item.children.length) {
					acc.push(...flatten(item.children))
				}
				return acc
			}, [])
		}
		const categories = flatten(state.categories)
		return categories.find(category => category.slug === slug)
	},
}

export const mutations = {
	setCollectionItem(state, { key, item }) {
		state[key] = item
	},
	setCollectionError(state, { key, error }) {
		state.collectionErrors[key] = error
	},
	setInitialDataStatus(state, payload) {
		state.initialDataLoaded = payload
	},
}

export const actions = {
	getCollection({ commit, state }, params) {
		for (const key in params) {
			params[key] = this.$to.base64(params[key])
		}
		return this.$axios.$get("/v1/collection", { params })

		// .catch((error) => {
		// 	// commit("setCollectionError", { key, error });
		// 	console.error("Store Collection:", error.response);

		// });
	},
	async nuxtServerInit({ commit, dispatch, getters, state }, { i18n, $axios, $cookies, route }) {
		if (state.initialDataLoaded) { return }

		if (route.name === 'health') {
			return
		}
		// await dispatch("getCollection", {
		// 	categories: "/v1/lookups/categories",
		// }).then((resp) => {
		// 	for (const key in resp) {
		// 		if (typeof resp[key] === "object" && resp[key].error) {
		// 			commit("setCollectionError", { key, error: resp[key].error });
		// 			this.$toast.warning(`"${key}" couldn't be loaded`, { title: "Collection Error" });
		// 		} else {
		// 			commit("setCollectionItem", { key, item: resp[key] });
		// 		}
		// 	}

		// 	if (getters.isAnyCollectionError) {
		// 		console.error("Store Collection:", getters.collectionErrors);
		// 	}

		// 	return resp;
		// });

		// if not logged in, get or create visitorId
		let visitorId = $cookies.get("visitorId")
			if (!visitorId) {
				// create visitorId
				visitorId = generateVisitorId()
				$cookies.set("visitorId", visitorId)
			}
			commit("setCollectionItem", { key: "visitorId", item: visitorId })

		// check currency from cookies
		const currency = $cookies.get("currency")
		if (currency) {
			commit("setCollectionItem", { key: "currency", item: currency })
		} else {
			$cookies.set("currency", state.currency)
		}
		const before = Date.now()
		await $axios
			.$get("/v1/categories")
			.then((resp) => {
				commit("setCollectionItem", { key: "categories", item: resp })
			})
			.catch((error) => {
				console.error("Store Collection:", error.response)
			})
		const after = Date.now()
		// eslint-disable-next-line
		console.log("[35m%s\x1b[0m", "Categories API Load time is: " + (after - before) + "ms");
		// await dispatch("auth/getPermissions");

		commit("setInitialDataStatus", true)
	},

	setvisitorId({ commit, dispatch, getters, state }, { i18n, $axios, $cookies, route }) {
		console.log("setvisitorId")
		let visitorId = $cookies.get("visitorId")
			if (!visitorId) {
				visitorId = generateVisitorId()
				$cookies.set("visitorId", visitorId)
			}
		commit("setCollectionItem", { key: "visitorId", item: visitorId })
	},
}
