export default async (context, locale) => {
	return await context.$axios
		.$get("/v1/lookups/translations/" + locale, {
			headers: {
				Language: locale,
			},
		})
		.then((resp) => {
			return resp
		})
		.catch((e) => {
			// if (process.browser) {
			// 	context.$toast.warning(`${locale} Translation couldn't be loaded from API`, { title: "API Error" });
			// }
		})
}
