
export default {
	name: "Logo",
	props: {
		to: {
			type: [String, Object],
			default: "/",
		},
	},
	data() {
		return {}
	},
}
