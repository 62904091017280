
import { mapGetters } from "vuex"

export default {
	data() {
		return {
			loading: false,
			otp: "",
			isDisabled: false,
			countdown: 30,
		}
	},

	computed: {
		...mapGetters({
			token: "authExtend/token",
		}),
	},
	mounted() { },

	methods: {
		submit() {
			if (!this.$refs.token.validate()) {
				this.scrollToError()
				return
			}
			this.loading = true
			this.visible = false
			// this.$store.dispatch("updateCode", this.otp);
			this.$store
				.dispatch("authExtend/verifyCode", { code: this.otp })
				.then(() => {
					this.$nuxt.$emit("auth.show", "verify-reset-password-code")
				})
				.catch((e) => {
					this.genericErrorHandler(e, this.$refs.token)
				})
				.finally(() => {
					this.loading = false
				})
		},
		async resend() {
			if (this.isDisabled) {
				return ""
			}

			// Disable the button and start the timer
			this.isDisabled = true
			this.countdown = 30
			const timer = setInterval(() => {
				this.countdown--
				if (this.countdown <= 0) {
					clearInterval(timer)
					this.isDisabled = false
				}
			}, 1000)

			// Dispatch the Vuex action to send the reset password email
			try {
				await this.$store.dispatch("authExtend/sendResetPasswordEmail", this.$store.state.authExtend.email)
				this.$toast.success(this.$t("auth.emailSentSuccess"))
			} catch (error) {
				console.error("Failed to send reset email:", error)
				this.$toast.error(this.$t("auth.email-sent-srror"), { timeout: 5000, showClose: true })
			}
		},
	},
}
