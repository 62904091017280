import { joinURL } from "ufo"
import { serialize } from "~/modules/QuerySerialization"
// import {} from '~image'
export function getImage(src, { modifiers, baseURL } = {}, { options, nuxtContext, $img }) {
	// process modifiers
	const renameMap = {
		// width: "w",
		"aspect-ratio": "aspect_ratio",
	}
	const mappedModifiers = {}
	// filter out modifiers that has value undefined
	modifiers = { ...modifiers, ...modifiers.modifiers } // fix nested modifiers
	delete modifiers.modifiers
	for (const key in modifiers) {
		const mappedKey = renameMap[key] || key
		if (typeof modifiers[key] !== "undefined") {
			mappedModifiers[mappedKey] = modifiers[key]
		}
	}

	const url = src.includes(baseURL)
		? joinURL(src + "?" + serialize(mappedModifiers))
		: joinURL(baseURL, src + "?" + serialize(mappedModifiers))

	return {
		url,
	}
}
