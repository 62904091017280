
export default {
	props: {
		title: {
			type: String,
			required: true,
		},
		items: {
			type: Array,
			required: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		to: {
			type: String,
			default: "",
		},
	},
	computed: {
		toRoute() {
			return this.to ? this.to : undefined
		},
	},
}
