
export default {
	data() {
		return {
			form: {
				email: null,
			},
			isLoading: false,
			apiError: [],
		}
	},
	methods: {
		submit() {
			if (!this.$refs.email.validate()) {
				this.scrollToError()
				return
			}
			this.isLoading = true
			this.$store.email = this.form
			this.$store
				.dispatch("authExtend/sendResetPasswordEmail", this.form)
				.then((resp) => {
					this.$nuxt.$emit("auth.show", "verify-token")
				})
				.catch((e) => {
					console.log(e)
					if (e.response.status === 422) {
						this.apiError = e.response.data.errors.email[0]
					} else {
						this.genericErrorHandler(e, this.$refs.email)
					}
				})
				.finally(() => {
					this.isLoading = false
				})
		},
	},
}
