import { render, staticRenderFns } from "./website.vue?vue&type=template&id=04f1fbf3&"
import script from "./website.vue?vue&type=script&lang=js&"
export * from "./website.vue?vue&type=script&lang=js&"
import style0 from "./website.vue?vue&type=style&index=0&id=04f1fbf3&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SecondaryBar: require('/app/components/SecondaryBar.vue').default,MainSearch: require('/app/components/MainSearch.vue').default,UserMenu: require('/app/components/User/Menu.vue').default,MobileMainMenu: require('/app/components/MobileMainMenu.vue').default,MobileSearchMenu: require('/app/components/MobileSearchMenu.vue').default,ProductsComparison: require('/app/components/ProductsComparison.vue').default,AuthModal: require('/app/components/Auth/Modal.vue').default,Toasts: require('/app/components/Toasts/index.vue').default,WishlistDrawer: require('/app/components/WishlistDrawer.vue').default,CartDrawer: require('/app/components/CartDrawer.vue').default})
