import Vue from "vue";
import * as vuetifyCustomizedFields from "../components/index.js";
// register all components
// for (const key in vuetifyCustomizedFields) {
// 	console.log(key, vuetifyCustomizedFields[key]);
// 	Vue.component(key, vuetifyCustomizedFields[key]);
// }
const options = JSON.parse(`{"prefix":"vc","style":{"dense":true,"filled":false,"rounded":false,"flat":false,"outlined":true},"icons":{"upload":"M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z","delete":"M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z","add":"M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z","eye":"M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z","eyeOff":"M11.83,9L15,12.16C15,12.11 15,12.05 15,12A3,3 0 0,0 12,9C11.94,9 11.89,9 11.83,9M7.53,9.8L9.08,11.35C9.03,11.56 9,11.77 9,12A3,3 0 0,0 12,15C12.22,15 12.44,14.97 12.65,14.92L14.2,16.47C13.53,16.8 12.79,17 12,17A5,5 0 0,1 7,12C7,11.21 7.2,10.47 7.53,9.8M2,4.27L4.28,6.55L4.73,7C3.08,8.3 1.78,10 1,12C2.73,16.39 7,19.5 12,19.5C13.55,19.5 15.03,19.2 16.38,18.66L16.81,19.08L19.73,22L21,20.73L3.27,3M12,7A5,5 0 0,1 17,12C17,12.64 16.87,13.26 16.64,13.82L19.57,16.75C21.07,15.5 22.27,13.86 23,12C21.27,7.61 17,4.5 12,4.5C10.6,4.5 9.26,4.75 8,5.2L10.17,7.35C10.74,7.13 11.35,7 12,7Z"},"api":{"countries":"/v1/lookups/countries","currencies":"/v1/lookups/currencies","provinces":"/v1/lookups/provinces"},"namespace":"vuetifyCustomFields"}`);

Object.entries(vuetifyCustomizedFields.default).forEach(([name, component]) => {
	Vue.component(options.prefix + name, component);
});
