
export default {
	data() {
		return {
			isLoggingIn: false,
			loginTypeModel: "new",
			userSelected: false,
			form: {
				phone: {
					iso: "JO",
					number: "",
					code: "962",
				},
				password: null,
			},

			socialMediaAuth: [
				{ name: "google", src: "/images/social-login/google.png", isLoading: false },
				{ name: "facebook", src: "/images/social-login/facebook.png", isLoading: false },
			],
		}
	},
	computed: {
		loginHistory() {
			return this.storage?.loginHistory
		},
	},
	watch: {
		"storage.loginHistory.length": {
			handler(val) {
				if (this.loginTypeModel === "history" && !val) {
					this.loginTypeModel = "new"
				}
			},
		},

		loginTypeModel(currentValue, oldValue) {
			if (oldValue === "reset-password") {
				this.$router.replace(this.localePath({ name: "login" }))
			}
		},
	},
	created() {
		if (this.storage?.loginHistory?.length) {
			this.loginTypeModel = "history"
		}
	},
	methods: {
		loginWithMedia(media) {
			media.isLoading = true
			// this.$auth.loginWith("SocialMedia", { provider: media.name }).finally(() => {
			// 	media.isLoading = false;
			// });
			return this.$store.dispatch(`authExtend/loginWithSocialMedia`, media.name).finally(() => {
				media.isLoading = false
				media.isLoggingIn = true
			})
		},
		submit() {
			const savePhone = this.form.phone.number
			console.log("this.form.phone", this.form.phone)
			if (this.loginTypeModel === "history") {
				this.form.phone = this.userSelected
			}

			if (!this.$refs.login.validate()) {
				this.scrollToError()
				return
			}

			this.isLoggingIn = true
			//! TODO: remove this line after testing
			// if (this.form.phone.code[0] !== "+") {
			// 	this.form.phone.code = +this.form.phone.code;
			// }
			this.$store
				.dispatch("authExtend/login", this.form)
				.then(() => {
					this.$nuxt.$emit("auth.success")
					this.$router.push(this.localePath({ name: "index" }))
				})
				.catch((e) => {
					this.genericErrorHandler(e, this.$refs.login)
				})
				.finally(() => {
					this.isLoggingIn = false
				})
			this.form.phone = {
				iso: "JO",
				number: savePhone,
				code: "962",
			}
		},
		historyItemOutsideClickHandler(e) {
			this.userSelected = false
			return true
		},
		deleteHistoryItem(item) {
			this.storage.loginHistory.splice(this.storage.loginHistory.indexOf(item), 1)
		},
		sendNewPassword() { },
	},
}
