
export default {
	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			setVisitorId: false,
			isInitiating: false,
		}
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit("input", value)
			},
		},
		categories() {
			return this.$store.state.categories
		},
		otherLocale() {
			return this.$i18n.locales.find(locale => locale.code !== this.$i18n.locale)
		},
	},
	methods: {
		logout() {
			this.$auth
				.logout()
				.then(() => {
					// check if this route has the middleware auth
					this.$router.push(this.localePath({ name: "index" }))
				})
		},
	},
}
