export default {
	data() {
		return {
			permanentDrawer: false,
			storage: {},
		}
	},
	methods: {
		setPermanentDrawer() {
			this.permanentDrawer = true
			setTimeout(() => {
				this.permanentDrawer = false
			})
		},
		stripTags(originalString) {
			if (typeof originalString !== "string") {
				return originalString
			}
			originalString = originalString.replace(/&nbsp;/gi, " ")
			return originalString.replace(/(<([^>]+)>)/gi, "")
		},
		scrollToError() {
			const errorElement = document.querySelector(".v-input--has-state.error--text")
			if (errorElement) {
				const headerHeight = 64 // change this to the actual height of your header in pixels
				const elementPosition = errorElement.getBoundingClientRect().top + window.pageYOffset
				const offsetPosition = elementPosition - headerHeight

				window.scrollTo({
					top: offsetPosition,
					behavior: "smooth",
				})
			}
		},
		isPublishable(item) {
			/*
			 item :{
				isPublished: Boolean,
				publishedAt: String, ISO 8601
				unPublishedAt: String, ISO 8601
			 }
			*/

			if (!item.isPublished) {
				return false
			}

			if (item.publishedAt && this.$dayjs().isBefore(this.$dayjs(item.publishedAt))) {
				return false
			}

			if (item.unPublishedAt && this.$dayjs().isAfter(this.$dayjs(item.unPublishedAt))) {
				return false
			}

			return true
		},
		toSlug(string) {
			if (typeof string === 'object') {
				const object = {}
				for (const key in string) {
					object[key] = this.toSlug(string[key])
				}
				return object
			} else if (typeof string === 'string') {
				return string
					.toLowerCase()
					.replace(/ /g, "-")
					.replace(/[^\wء-ي-]+/g, "")
					.replace(/--+/g, "-")
			} else {
				console.warn('toSlug() expects a string or object as a parameter')
				return string
			}
		},
		amountColor(amount) {
			if (amount > 0) {
				return "positive"
			} else if (amount < 0) {
				return "negative"
			} else {
				return "grey"
			}
		},
	},
	computed: {
		isFetching() {
			return this.$fetchState?.pending && !this.$fetchState.error
		},

		// isNowBetween(start, end) {
		// 	return start && this.$dayjs().isBefore(this.$dayjs(start)) && end && this.$dayjs().isAfter(this.$dayjs(end));
		// },

	},
}
