
export default {
	data() {
		return {
			model: 0,
			data: [
				{
					bg: "/images/slide1.webp",
					title: "The NEW Samsung AI S24",
					text: "Experience the future of display technology with the Samsung S24 Monitor, where every pixel is a testament to innovation and engineering excellence. ",
					// img: require("~/assets/uploads/mac-book-pro.png"),
				},
				{
					bg: "/images/slide2.webp",
					title: "IPhone 14 Pro is Back",
					text: "The iPhone 14 is Apple's latest addition to their smartphone lineup as of my knowledge cutoff date in early 2023. Below are some key points about the iPhone 14 ",
					// img: require("~/assets/uploads/mac-book-pro.png"),
				},
			],
		}
	},
	methods: {
		onKeydown(e) {
			e.stopPropagation() // do not propagate the keydown event
		},
	},
}
