
export default {
	name: "Base",

	props: {
		contentClass: {
			type: [String, Array, Object],
			default: null,
		},
	},
	data() {
		return {
			nuxtServerInit: false,
			isInitiating: false,
		}
	},
	head() {
		return {
			link: [
				{
					rel: "stylesheet",
					href: `/fonts/${this.$i18n.locale}.css`,
				},
			],
		}
	},

	beforeCreate() {
		this.$vuetify.rtl = this.$i18n.localeProperties.dir === "rtl"
	},
	created() {
		// this.$vuetify.theme.dark = this.storage?.theme === "dark";
	},
	mounted() {
		window.onNuxtReady((app) => {
			this.$vuetify.theme.dark = this.storage?.theme === "dark"
		})

		window.addEventListener(
			"keydown",
			function (e) {
				// console.log(e);
				if (e.key === "F8") {
					/* eslint-disable-next-line */
					debugger;
				}
			},
			false,
		)

		this.$store.dispatch("nuxtServerInit", this.$nuxt.context)
	},
}
