import Vue from "vue"
import Teleport from "vue2-teleport"
import ZoomOnHover from "vue-zoom-on-hover"

import ReactiveStorage from "./ReactiveLocalStorage"

// export default ({ app }, inject) => {
// Vue.filter("dateFromNow", (value) => {
//     return app
//         .$dayJs(value)
//         .locale(app.i18n.locale || "ar")
//         .fromNow();
// });

Vue.mixin({ components: { Teleport } })

const vuePositionSticky = require("vue-position-sticky")
// const DatetimePicker = require("vuetify-datetime-picker");
Vue.use(vuePositionSticky)
// Vue.use(DatetimePicker);
// Set initial values
Vue.use(ReactiveStorage, {
	loginHistory: [],
	wishlist: [],
	cart: [],
	miniDrawer: false,
})

Vue.use(ZoomOnHover)
// };
