
import {
    mapGetters,
} from "vuex"
export default {
    data() {
        return {
            form: {
                password: "",
                passwordConfirmation: "",
            },
            isSendingNewPassword: false,
        }
    },

    computed: {
        ...mapGetters({
            token: "authExtend/token",
        }),
    },

    methods: {
        submit() {
            if (!this.$refs.password.validate()) {
                this.scrollToError()
                return
            }

            this.isSendingNewPassword = true
            this.$store
                .dispatch("authExtend/sendNewPasswordOfToken", {
                    form: this.form,
                    token: this.token,
                })
                .then(() => {
                    this.$toast.success(this.$t("auth.password-has-been-updated-successfully"))
                    this.$nuxt.$emit("auth.show", "login")
                })
                .catch((e) => {
                    this.genericErrorHandler(e, this.$refs.password)
                })
                .finally(() => {
                    this.isSendingNewPassword = false
                })
        },
    },
}
