
import { mapState, mapGetters, mapActions } from "vuex"
export default {
	data() {
		return {
			...mapState,
			drawerModel: false,
			isCheckingOut: false,
		}
	},
	computed: {
		...mapGetters,
	},
	watch: {
		"$auth.loggedIn": {
			handler(isLoggedIn) {
				this.get()
			},
			immediate: true,
		},
	},
	mounted() {
		this.$nuxt.$on("cart.show", this.open)
		this.$nuxt.$on("cart.hide", this.close)
		this.$nuxt.$on("cart.add", this.add)
		this.$nuxt.$on("cart.remove", this.remove)
	},
	methods: {
		...mapActions({
			add: "cart/add",
			remove: "cart/remove",
			get: "cart/get",
			setQuantity: "cart/setQuantity",
			moveToWishlist: "cart/moveToWishlist",
		}),
		open() {
			this.drawerModel = true
		},
		close() {
			this.drawerModel = false
		},
		removeWithConfirm(item) {
			this.$confirm(this.$t("product.are-you-sure-you-want-to-remove-this-item-from-your-cart"), {
				title: this.$t("product.remove-item"),
				prepend: [
					{
						text: "Just Remove",
						color: "error",
						flat: true,
						value: true,
					},
				],
				btns: [
					{
						text: "Cancel",
						flat: true,
						value: false,
					},
					{
						text: "Move to my wishlist",
						color: "primary",
						flat: true,
						value: "move",
					},
				],
			})
				.then((value) => {
					this.setPermanentDrawer()
					if (value === "move") {
						this.moveToWishlist(item)
					} else if (value) {
						this.remove(item)
					}
				})
				.catch(() => { })
		},
		incrementQuantity(item) {
			item = this.$cloneDeep(item)
			item.quantity++
			this.setQuantity(item)
		},
		decrementQuantity(item) {
			item = this.$cloneDeep(item)
			item.quantity--
			if (item.quantity < 1) {
				this.removeWithConfirm(item)
			} else {
				this.setQuantity(item)
			}
		},
		checkout() {
			this.isCheckingOut = true
			this.$axios
				.$post("/v1/orders/store")
				.then((resp) => {
					this.isCheckingOut = false
					this.$router.push(
						this.localePath({
							name: "checkout",
							params: {
								orderId: resp.orderId,
								step: 1,
							},
						}),
					)
					this.close()
				})
				.finally(() => {
					this.isCheckingOut = false
				})
		},
	},
}
