
export default {
	data() {
		return {
			isLoggingOut: false,
			setVisitorId: false,
			isInitiating: false,
		}
	},

	methods: {
		checkHaveAdminDashboard() {
			return (
				Array.isArray(this.$auth.user.roles) &&
				(this.$auth.user.roles.includes("admin") ||
					this.$auth.user.roles.includes("editor") ||
					this.$auth.user.roles.includes("accounting"))
			)
		},
		logout() {
			this.isLoggingOut = true
			this.$auth
				.logout()
				.then(() => {
					// check if this route has the middleware auth
					this.$router.push(this.localePath({ name: "index" }))
				})
				.finally(() => {
					this.isLoggingOut = false
				})
		},
	},
}
