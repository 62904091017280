export function generateVisitorId() {
    const timestamp = new Date().getTime().toString()
    let id = ''

    for (let i = 0; i < timestamp.length; i++) {
        const digit = timestamp.charAt(i)

        if (!isNaN(digit)) {
            id += digit
        }
    }

    return id
}
