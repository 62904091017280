// import {  mapActions } from "vuex";
// import { getKey } from "~/modules/localstorage.js";
// const isDev = process.env.NODE_ENV === "development";
export default {
	// data() {
	// 	return {
	// 		isDev,
	// 	};
	// },
	// computed: {
	// 	portalType() {
	// 		if (this.isDev) {
	// 			return getKey("debugPortalType") || this.$store.state.debug.portalType;
	// 		} else {
	// 			return this.$config.portalType;
	// 		}
	// 	},
	// },
	computed: {
		getPreferencesByRoute() {
			return this.$store.getters["authExtend/getPreferencesByRoute"](this.getRouteBaseName())
		},
		isPreferencesSaving() {
			return this.$store.state.authExtend.isSaving
		},
			// ...mapGetters('config')
			// config() {
			// 	return this.$store.state.config;
			// },
	},
	methods: {
		setPreferencesByRoute([key, value]) {
			this.$store.dispatch("authExtend/setPreferences", [`${this.getRouteBaseName()}.${key}`, value])
		},
		setGlobalPreferences([key, value]) {
			this.$store.dispatch("authExtend/setPreferences", [`global.${key}`, value])
		},
		localized(obj) {
			return obj[this.$i18n.locale]
		},
	},
}
