
export default {
	props: {
		src: {
			type: String,
			required: true,
		},
		width: {
			type: [Number],
			default: 100,
		},
		aspectRatio: {
			type: Number,
			default: 16 / 9,
		},
		previewTitle: {
			type: String,
			default: "",
		},
		contain: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showModel: false,
			loading: true,
			previewWidth: 400,
		}
	},
	computed: {
		height() {
			return this.width / this.aspectRatio
		},
	},
	methods: {
		imgLoadedHandler() {
			this.loading = false
		},
	},
}
