
export default {
	name: "Toasts",

	props: {},
	data() {
		return {
			objects: [],
		}
	},
	mounted() {
		this.$nuxt.$on("toast.add", this.add)
	},

	methods: {
		add(obj) {
			this.objects.push(obj)
		},
	},
}
