
import field from "../mixins/field";
export default {
	mixins: [field],
	props: {
		type: {
			type: String,
			default: undefined,
		},
	},
	data() {
		return {
			searchModel: null,
			timeout: null,
		};
	},
	async fetch() {
		// if (!this.searchModel) {
		// 	this.apiItems = [];
		// 	return;
		// }

		await this.$axios
			.$get(`/v2/lookups/users`, {
				params: {
					search: this.searchModel || undefined,
					ids: this.localValue?.length ? this.localValue.join() : undefined,
					type: this.type,
				},
			})
			.then((items) => {
				this.apiItems = items;
			});
	},
	computed: {
		localItems() {
			return this.apiItems;
		},
	},
	methods: {
		search() {
			// delay the fetch by 500 ms
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.$fetch();
			}, 500);
		},
		remove(item) {
			const index = this.friends.indexOf(item.name);
			if (index >= 0) this.friends.splice(index, 1);
		},
	},
};
