export const state = () => ({
	items: [],
})

export const getters = {
	isWishlisted: state => (productId) => {
		return state.items && state.items.some(item => item.productId === productId)
	},
	wishlistItems: (state) => {
		if (state && state.items) {
			return state.items
		} else {
			return []
		}
	},

}
export const mutations = {
	setItems(state, payload) {
		state.items = [...payload]
	},
	addItem(state, payload) {
		state.items.push(payload)
	},
	removeItem(state, payload) {
		const index = state.items.findIndex(item => item.productId === payload.productId)
		state.items.splice(index, 1)
	},
}

export const actions = {
	get({ commit }) {
		return this.$axios.$get("/v1/wishlist").then((resp) => {
			commit("setItems", resp)
		})
	},
	add({ commit }, payload) {
		return this.$axios.$post("/v1/wishlist", { productId: payload.productId }).then((resp) => {
			commit("addItem", payload)
		})
	},
	remove({ commit }, payload) {
		return this.$axios.$delete(`/v1/wishlist/${payload.productId}`).then((resp) => {
			commit("removeItem", payload)
		})
	},
	toggle({ dispatch, getters }, payload) {
		if (getters.isWishlisted(payload.productId)) {
			return dispatch("remove", payload)
		} else {
			return dispatch("add", payload)
		}
	},
}
