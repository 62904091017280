
export default {
	props: {
		value: {
			type: String, // created || updated || deleted
			required: true,
			validator: (value) => {
				return ["created", "updated", "deleted"].includes(value)
			},
		},
	},
	computed: {
		text() {
			return this.value
		},
		color() {
			switch (this.value) {
				case "created":
					return "green"
				case "updated":
					return "orange"
				case "deleted":
					return "red"
				default:
					return "blue"
			}
		},
	},
}
