
export default {
	name: "VSnackbars",
	components: {
		"css-style": {
			render(createElement) {
				return createElement("style", this.$slots.default)
			},
		},
	},
	inheritAttrs: false,
	props: {
		messages: {
			type: Array,
			default: () => [],
		},
		timeout: {
			type: [Number, String],
			default: 5000,
		},
		distance: {
			type: [Number, String],
			default: 55,
		},
		objects: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			len: 0, // we need it to have a css transition
			snackbars: [], // array of {key, message, top, right, left, bottom, color, transition, timeout, show}
			keys: [], // array of 'keys'
			heights: {}, // height of each snackbar to correctly position them
			identifier: Date.now() + (Math.random() + "").slice(2), // to avoid issues when several v-snackbars on the page
			intervals: {}, // intervals for each snackbar
		}
	},
	computed: {
		allMessages() {
			if (this.objects.length > 0) { return this.objects.map(o => o.message) }
			return this.messages
		},
		// to correcly position the snackbar
		indexPosition() {
			const ret = {}
			const idx = {
				topCenter: 0,
				topLeft: 0,
				topRight: 0,
				bottomCenter: 0,
				bottomLeft: 0,
				bottomRight: 0,
			}
			this.snackbars.forEach((o) => {
				if (o.top && !o.left && !o.right) { ret[o.key] = idx.topCenter++ }
				if (o.top && o.left) { ret[o.key] = idx.topLeft++ }
				if (o.top && o.right) { ret[o.key] = idx.topRight++ }
				if (o.bottom && !o.left && !o.right) { ret[o.key] = idx.bottomCenter++ }
				if (o.bottom && o.left) { ret[o.key] = idx.bottomLeft++ }
				if (o.bottom && o.right) { ret[o.key] = idx.bottomRight++ }
			})
			return ret
		},
		topOrBottom() {
			const ret = {}
			this.snackbars.forEach((o) => {
				ret[o.key] = o.top ? "top" : "bottom"
			})
			return ret
		},
	},
	watch: {
		// messages() {
		// 	this.eventify(this.messages);
		// },
		objects: {
			handler() {
				this.eventify(this.objects)
			},
			deep: true,
		},
	},
	created() {
		this.eventify(this.messages)
		this.eventify(this.objects)
	},
	methods: {
		getProp(prop, i) {
			if (this.objects.length > i && typeof this.objects[i][prop] !== "undefined") { return this.objects[i][prop] }
			if (typeof this.$attrs[prop] !== "undefined") { return this.$attrs[prop] }
			if (typeof this[prop] !== "undefined") { return this[prop] }
			return undefined
		},
		setSnackbars() {
			console.log("toast setSnakBars")
			for (let i = this.snackbars.length; i < this.allMessages.length; i++) {
				const key = new Date().getTime() + "-" + i
				let top = this.getProp("top", i)
				let bottom = this.getProp("bottom", i)
				let left = this.getProp("left", i)
				let right = this.getProp("right", i)
				top = top === "" ? true : top
				bottom = bottom === "" ? true : bottom
				left = left === "" ? true : left
				right = right === "" ? true : right
				// by default, it will be at the bottom
				if (!bottom && !top) { bottom = true }
				this.snackbars.push({
					key,
					message: this.allMessages[i],
					top,
					bottom,
					left,
					right,
					color: this.getProp("color", i) || "black",
					contentClass: this.getProp("content-class", i) || this.getProp("contentClass", i) || "",
					timeout: null,
					remaining: 100,
					transition: this.getProp("transition", i) || (right ? "slide-x-reverse-transition" : "slide-x-transition"),
					show: false,
					// custom
					icon: this.getProp("icon", i), // default
					iconColor: this.getProp("iconColor", i), // default
					classes: this.getProp("classes", i) || [],
					remainingTimeout: 0,
					title: this.getProp("title", i),
					dismissable: this.getProp("dismissable", i),
					multiLine: this.getProp("multiLine", i),
					vertical: this.getProp("vertical", i),
					queueable: this.getProp("queueable", i),
					showClose: this.getProp("showClose", i),
					closeText: this.getProp("closeText", i),
					closeIcon: this.getProp("closeIcon", i),
					closeColor: this.getProp("closeColor", i),
				})
				this.keys.push(key)
				this.$nextTick(function () {
					this.snackbars[i].show = true // to see the come-in animation

					this.$nextTick(function () {
						// find the correct height
						let height = this.distance
						const elem = document.querySelector(".v-snackbars-" + this.identifier + "-" + key)

						if (elem) {
							const wrapper = elem.querySelector(".v-snack__wrapper")
							if (wrapper) {
								height = wrapper.clientHeight + 7
							}
						}
						this.$set(this.heights, key, height)

						// define the timeout
						const timeout = this.getProp("timeout", i)
						if (timeout > 0) {
							// this.snackbars[i].timeout = () => {
							this.snackbars[i].timeout = timeout
							const precision = 100
							this.snackbars[i].remainingTimeout = timeout
							console.log("toast setting intervals")
							const self = this

							self.intervals[key] = setInterval(
								function (i, key) {
									// console.log("timeout", i, this.snackbars[i].remainingTimeout);
									// console.log("toast i", i, self.snackbars[i]);
									if (!self.snackbars[i]) {
										clearInterval(self.intervals[key])
										return
									}
									self.snackbars[i].remainingTimeout -= precision
									self.snackbars[i].remaining = ((self.snackbars[i].remainingTimeout / timeout) * 100).toFixed()

									if (self.snackbars[i].remaining <= 0) {
										// console.log("toast clearing interval key", key, this.snackbars[i].key);
										// console.log("toast clearing interval key", key);
										// clearInterval(self.intervals[key]);
										// if a timeout on the snackbar, clear it
										if (self.intervals[key]) {
											clearInterval(self.intervals[key])
											delete self.intervals[key]
										}
										self.removeMessage(key, true)
									}
								},
								precision,
								i,
								key,
							)
						}
					})
				})
			}
		},
		removeMessage(key, fromComponent) {
			// console.log("toast removeMessage", key);

			const idx = this.snackbars.findIndex(s => s.key === key)

			// console.log("toast removeMessage idx", key, idx);
			if (idx > -1) {
				this.snackbars[idx].show = false

				const removeSnackbar = (key) => {
					// const idx = this.snackbars.findIndex((s) => s.key === key);
					// this.snackbars.splice(idx, 1);
					// dipose all
					this.keys = this.keys.filter(k => k !== key)
					delete this.heights[key]
					// only send back the changes if it happens from this component
					// if (fromComponent) {
					// 	this.$emit(
					// 		"update:messages",
					// 		this.allMessages.filter((m, i) => i !== idx)
					// 	);
					// 	this.$emit(
					// 		"update:objects",
					// 		this.objects.filter((m, i) => i !== idx)
					// 	);
					// }
				}

				// use a timeout to ensure the 'transitionend' will be triggerred
				// const timeout = setTimeout(removeSnackbar, 600, key);

				// 	// skip waiting if key does not exist
				const ref = this.$refs["v-snackbars-" + this.identifier]
				// 	if (!ref || !ref[idx]) return;

				// 	// wait the end of the animation
				ref[idx].$el.addEventListener(
					"transitionend",
					() => {
						// clearTimeout(timeout);
						removeSnackbar(key)
					},
					{ once: true },
				)
			} else {
				//	console.log("toast removeMessage not found", idx, key, this.snackbars);
			}
		},
		calcDistance(key) {
			// calculate the position in the stack for the snackbar
			let distance = 0
			const snackbar = this.snackbars.find(s => s.key === key)
			if (!snackbar) { return 0 }
			for (let i = 0; i < this.snackbars.length; i++) {
				// we add all the heights for each visible snackbar in the same corner
				if (this.snackbars[i].key === key) { break }
				if (
					this.snackbars[i].show &&
					this.snackbars[i].bottom === snackbar.bottom &&
					this.snackbars[i].top === snackbar.top &&
					this.snackbars[i].right === snackbar.right &&
					this.snackbars[i].left === snackbar.left
				) {
					distance += this.heights[this.snackbars[i].key] || 0
				}
			}

			return distance
		},
		eventify(arr) {
			// detect changes on 'messages' and 'objects'
			const _this = this
			const eventify = function (arr) {
				arr.isEventified = true
				// overwrite 'push' method
				const pushMethod = arr.push
				arr.push = function (e) {
					pushMethod.call(arr, e)
					_this.setSnackbars()
				}
				// overwrite 'splice' method
				const spliceMethod = arr.splice
				arr.splice = function () {
					const args = []
					let len = arguments.length
					while (len--) { args[len] = arguments[len] }
					spliceMethod.apply(arr, args)
					let idx = args[0]
					let nbDel = args[1]
					const elemsLen = args.length - 2

					// do we just remove an element?
					if (elemsLen === 0) {
						nbDel += idx
						while (idx < nbDel) {
							if (_this.snackbars[idx]) {
								_this.removeMessage(_this.snackbars[idx].key)
							}
							idx++
						}
					} else if (elemsLen > 0) {
						// or we set a value on an element using this.$set, so we update the message
						for (let i = 2; i < elemsLen + 2; i++) {
							if (typeof args[i] === "string") {
								_this.$set(_this.snackbars[idx], "message", args[i])
							} else if (typeof args[i] === "object") {
								for (const prop in args[i]) {
									if (prop === "timeout") {
										const timeout = args[i][prop] * 1
										// if there's an existing timeout, clear it before setting the new timeout
										if (_this.snackbars[idx].timeout) {
											clearTimeout(_this.snackbars[idx].timeout)
											_this.snackbars[idx].timeout = null
										}
										if (timeout > -1) {
											const key = _this.snackbars[idx].key
											_this.snackbars[idx].timeout = setTimeout(() => {
												_this.removeMessage(key, true)
											}, timeout)
										}
									} else {
										// update the property
										_this.$set(_this.snackbars[idx], prop, args[i][prop])
									}
								}
							}
						}
						idx++
					}
				}
			}
			if (!arr.isEventified) { eventify(arr) }
		},
	},
}
