export const state = () => ({
	keys: {},
})

export const getters = {
	isKeyExist: state => (key) => {
		return state.keys[key?.toLowerCase()] !== undefined
	},
	getKey: state => (key) => {
		return state.keys[key?.toLowerCase()]
	},
	isPromiseExist: state => (key) => {
		return state.keys[key?.toLowerCase()]?.value instanceof Promise
	},
	getPromise: state => (key) => {
		return state.keys[key?.toLowerCase()]?.value
	},
}
export const mutations = {
	setKey(state, payload) {
		state.keys[payload.key?.toLowerCase()] = { date: new Date(), value: payload.value }
	},
	setPromise(state, payload) {
		state.keys[payload.key?.toLowerCase()] = { date: new Date(), value: payload.value }
	},
	clearKey(state, key) {
		this._vm.$delete(state.keys, key?.toLowerCase())
	},
}

export const actions = {
	insertKey({ commit }, payload) {
		commit("setKey", payload)
	},
	insertPromise({ commit }, payload) {
		commit("setPromise", payload)
	},
	clearKey({ commit }, key) {
		commit("clearKey", key)
	},
}
