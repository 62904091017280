
import field from "../mixins/field";
export default {
	mixins: [field],
	props: {
		value: {
			type: Object,
			default: () => {
				return { country_id: null, province_id: null };
			},
		},
	},
	computed: {
		localValue: {
			get() {
				return this.value;
			},
			set(value) {
				return this.$emit("input", value || { country_id: null, province_id: null });
			},
		},
		localCountry: {
			get() {
				return this.value?.country_id;
			},
			set(value) {
				//this.localValue = { country_id: value, province_id: null };
			},
		},
		localProvince: {
			get() {
				return this.value?.province_id;
			},
			set(value) {
				//this.localValue = { country_id: this.localCountry, province_id: value };
			},
		},
	},
	methods: {
		reloadProvinces() {
			this.$refs.provinces.$fetch();
		},
	},
};
