import cloneDeep from "clone-deep"

const isEqual = function (x, y) {
	if (x === y) {
		return true
	} else if (typeof x === "object" && x != null && typeof y === "object" && y != null) {
		if (Object.keys(x).length !== Object.keys(y).length) { return false }

		for (const prop in x) {
			/* eslint-disable-next-line */
			if (y.hasOwnProperty(prop)) {
				if (!isEqual(x[prop], y[prop])) { return false }
			} else { return false }
		}

		return true
	} else { return false }
}

export default (_context, inject) => {
	inject("cloneDeep", cloneDeep)
	inject("isEqual", isEqual)
}
