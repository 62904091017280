
import { mapActions } from "vuex"
export default {
	data() {
		return {
			bannerModel: false,
		}
	},
	computed: {
		items() {
			return this.$store.state.comparison.items
		},
		count() {
			return this.items.length
		},
	},
	watch: {
		count(now, before) {
			if (before === 0 && now > 0) {
				this.bannerModel = true
			} else if (now === 0) {
				this.bannerModel = false
			}
		},
		bannerModel(now) {
			this.$emit("update:bannerModel", now)
		},
	},
	mounted() {
		this.$nuxt.$on("comparison:show", this.open)
		this.$nuxt.$on("comparison:hide", this.close)
		this.$nuxt.$on("comparison:add", this.add)
		this.$nuxt.$on("comparison:remove", this.remove)
		this.topPositionAdjuster()
	},
	methods: {
		...mapActions("comparison", { add: "add", remove: "remove", removeAll: "removeAll" }),
		confirmRemoveAll() {
			this.$confirm("Are you sure you want to clear the comparison list?").then((x) => {
				if (x) {
					this.removeAll()
				}
			})
		},
		topPositionAdjuster() {
			// Select the .main-header element
			const mainHeader = document.querySelector(".main-header")

			// Create a new MutationObserver
			const observer = new MutationObserver((x) => {
				// console.log("🚀 ~ file: ProductsComparison.vue:79 ~ observer ~ x:", x);
				// Retrieve the current translateY value from the element's transform property
				const transformValue = window.getComputedStyle(mainHeader).getPropertyValue("transform")
				const translateY = transformValue.split(",")[5].trim()

				// Convert the translateY value to pixels
				const translateYInPixels = parseInt(translateY)

				// Adjust the top value based on the translateY value
				const adjustedTop = 64 - translateYInPixels + "px"
				// console.log("🚀 ~ file: ProductsComparison.vue:89 ~ observer ~ adjustedTop:", adjustedTop);

				// Update the top value of your target element
				// Replace 'target-element' with the appropriate selector for your target element
				const targetElement = document.querySelector(".comparison-banner")
				targetElement.style.top = adjustedTop
			})

			// Start observing changes in the .main-header element's style
			observer.observe(mainHeader, { attributes: true, attributeFilter: ["style"] })
		},
	},
}
