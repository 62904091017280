
export default {
	props: {
		window: {
			type: String,
			default: "main",
		},
	},
	data() {
		return {
			/// windowModel: "main",
		}
	},
	computed: {
		localWindow: {
			get() {
				return this.window
			},
			set(v) {
				this.$emit("update:window", v)
			},
		},
	},
	methods: {
		setWindow(v) {
			this.localWindow = v
		},
	},
}
