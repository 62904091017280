// import Router from "vue-router";
// const createRouter = () =>
// 	new Router({
// 		mode: "history",
// 		routes: [],
// 	});
export default (_context, inject) => {
	// function resetRouter(router, newRoutes = []) {
	// 	const newRouter = createRouter();
	// 	router.matcher = newRouter.matcher; // reset router
	// 	router.options.routes = newRoutes;
	// }
	// async function loadRoutesByPortalTypes(type) {
	// 	console.log("loadRoutesByPortalTypes", type);
	// 	const routes = await import(`~/routes/${type}.js`).then((m) => m.default || m);

	// 	const codes = _context.i18n.locales.map(({ code }) => code);
	// 	const newRoutes = [];
	// 	codes.forEach((code) => {
	// 		routes.forEach((route) => {
	// 			newRoutes.push({
	// 				...route,
	// 				path: "/" + code + (route.path ? "/" + route.path.replace(/^\//, "") : ""),
	// 				name: route.name + "___" + code,
	// 			});
	// 		});
	// 	});

	// 	resetRouter(_context.app.router, newRoutes);
	// 	if (window) {
	// 		// window.location.reload();
	// 	}
	// }

	// inject("loadRoutesByPortalTypes", loadRoutesByPortalTypes);
}
