
import { Editor, EditorContent } from "@tiptap/vue-2";
import TextAlign from "@tiptap/extension-text-align";
// import TextDirection from "tiptap-text-direction-extension";
import Link from "@tiptap/extension-link";
import StarterKit from "@tiptap/starter-kit";
import field from "../mixins/field";
import { Extension } from "@tiptap/core";
import {
	mdiFormatBold,
	mdiFormatItalic,
	mdiFormatStrikethrough,
	mdiFormatAlignLeft,
	mdiFormatAlignCenter,
	mdiFormatAlignRight,
	mdiFormatListBulleted,
	mdiFormatListNumbered,
	mdiFormatPilcrowArrowRight,
	mdiFormatPilcrowArrowLeft,
	mdiFormatHeader2,
	mdiFormatHeader3,
	mdiFormatHeader4,
	mdiLink,
	mdiCodeJson,
	mdiFormatClear,
} from "@mdi/js";
const TextDirection = Extension.create({
	name: "textDirection",
	addOptions() {
		return {
			types: ["heading", "paragraph"],
			directions: ["ltr", "rtl", "auto"],
			defaultDirection: "auto",
		};
	},
	addGlobalAttributes() {
		return [
			{
				types: this.options.types,
				attributes: {
					dir: {
						default: this.options.defaultDirection,
						parseHTML: (element) => {
							if (element.attributes.dir && this.options.directions.includes(element.attributes.dir)) {
								return element.attributes.dir.value;
							} else {
								return this.options.defaultDirection;
							}
						},
						renderHTML: (attributes) => {
							return { dir: attributes.dir };
						},
					},
				},
			},
		];
	},
	addCommands() {
		return {
			setTextDirection:
				(direction) =>
				({ commands }) => {
					if (!this.options.directions.includes(direction)) {
						return false;
					}
					return this.options.types.every((type) => commands.updateAttributes(type, { dir: direction }));
				},
			unsetTextDirection:
				() =>
				({ commands }) => {
					return this.options.types.every((type) => commands.resetAttributes(type, "dir"));
				},
		};
	},
});
export default {
	components: {
		EditorContent,
	},
	mixins: [field],
	props: {
		value: {
			type: String,
			default: null,
		},
		rules: {
			type: Array,
			default: () => [],
		},
		showSuccess: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			mdiFormatClear,
			linkModal: false,
			isLinkFormValid: false,
			linkText: "",
			editor: null,
			isMounted: false,
			toolbar: [
				{
					icon: mdiFormatBold,
					title: "Bold",
					command: "toggleBold",
					activeStateName: "bold",
					type: "button",
				},
				{
					icon: mdiFormatItalic,
					title: "Italic",
					command: "toggleItalic",
					activeStateName: "italic",
					type: "button",
				},
				{
					icon: mdiFormatStrikethrough,
					title: "Strike",
					command: "toggleStrike",
					activeStateName: "strike",
					type: "button",
				},
				{
					type: "divider",
				},
				{
					icon: mdiFormatAlignLeft,
					title: "Align Left",
					command: "setTextAlign",
					commandParam: "left",
					activeStateName: {
						textAlign: "left",
					},
					type: "button",
				},
				{
					icon: mdiFormatAlignCenter,
					title: "Align Center",
					command: "setTextAlign",
					commandParam: "center",
					activeStateName: {
						textAlign: "center",
					},
					type: "button",
				},
				{
					icon: mdiFormatAlignRight,
					title: "Align Right",
					command: "setTextAlign",
					commandParam: "right",
					activeStateName: {
						textAlign: "right",
					},
					type: "button",
				},
				{
					type: "divider",
				},
				{
					icon: mdiFormatListBulleted,
					title: "Bullet List",
					command: "toggleBulletList",
					activeStateName: "bulletList",
					type: "button",
				},
				{
					icon: mdiFormatListNumbered,
					title: "Ordered List",
					command: "toggleOrderedList",
					activeStateName: "orderedList",
					type: "button",
				},
				{
					type: "divider",
				},
				{
					icon: mdiFormatPilcrowArrowRight,
					title: "Left to right",
					command: "setTextDirection",
					commandParam: "ltr",
					activeStateName: {
						text_direction: "ltr",
					},
					type: "button",
				},
				{
					icon: mdiFormatPilcrowArrowLeft,
					title: "Right to left",
					command: "setTextDirection",
					commandParam: "rtl",
					activeStateName: {
						text_direction: "rtl",
					},
					type: "button",
				},
				{
					type: "divider",
				},
				{
					icon: mdiFormatHeader2,
					title: "Heading 2",
					command: "toggleHeading",
					commandParam: {
						level: 2,
					},
					activeStateName: "heading",

					type: "button",
				},
				{
					icon: mdiFormatHeader3,
					title: "Heading 3",
					command: "toggleHeading",
					commandParam: {
						level: 3,
					},
					activeStateName: "heading",

					type: "button",
				},
				{
					icon: mdiFormatHeader4,
					title: "Heading 4",
					command: "toggleHeading",
					commandParam: {
						level: 4,
					},
					activeStateName: "heading",

					type: "button",
				},

				{
					type: "divider",
				},
				{
					icon: mdiLink,
					title: "Link",
					command: "toggleLink",
					activeStateName: "link",
					type: "link",
				},

				{
					icon: mdiCodeJson,
					title: "Code",
					command: "toggleCode",
					activeStateName: "code",
					type: "button",
				},
			],
			lastContent: null,
		};
	},

	computed: {},
	watch: {
		value(value) {
			if (this.lastContent !== value) {
				if (this.editor) this.editor.commands?.setContent(value);
			}
		},
	},
	mounted() {
		const self = this;
		const regex = /<br data-placeholder>/g;
		const content = this.localValue?.replace(regex, '<p dir="auto"></p>');
		this.editor = new Editor({
			extensions: [
				TextDirection.configure({
					types: ["heading", "paragraph", "bulletList", "orderedList"],
				}),
				StarterKit,
				TextAlign.configure({
					types: ["heading", "paragraph"],
				}),
				Link.configure({
					openOnClick: false,
				}),
			],
			content,
			onUpdate: ({ editor }) => {
				const html = editor.getHTML();
				let content = !html || html === "<p></p>" ? null : html;
				content = self.replaceEmptyPTagsWithBr(content)

				self.lastContent = content;
				self.localValue = content;
			},
		});
		this.lastContent = this.localValue;
		this.isMounted = true;
	},

	beforeDestroy() {
		this.editor.destroy();
	},
	methods: {
		showModal() {
			const previousUrl = this.editor.getAttributes("link").href;
			this.linkText = previousUrl;
			this.linkModal = true;
		},
		setLink() {
			// cancelled
			if (this.linkText === null) {
				return;
			}

			// empty
			if (this.linkText === "") {
				this.editor.chain().focus().extendMarkRange("link").unsetLink().run();

				return;
			}

			// update link
			this.editor.chain().focus().extendMarkRange("link").setLink({ href: this.linkText }).run();
			this.linkModal = false;
		},
		removeLink() {
			this.editor.chain().focus().extendMarkRange("link").unsetLink().run();
			this.linkModal = false;
		},
		replaceEmptyPTagsWithBr(htmlString) {
			let parser = new DOMParser();
			let doc = parser.parseFromString(htmlString, 'text/html');

			let pTags = doc.getElementsByTagName('p');
			for (let i = 0; i < pTags.length; i++) {
				if (pTags[i].innerHTML.trim() === '') {
					pTags[i].innerHTML = '<br data-placeholder>';
				}
			}

			return doc.body.innerHTML;
		}
	},
};
