import Vue from "vue"
import VuetifyConfirm from "~/modules/VuetifyConfirm"

let app = null

const init = (params) => {
	Vue.use(VuetifyConfirm, {
		vuetify: app.vuetify,
		btns: [
			{
				text: app.i18n.t("common.no"),
				value: false,

				flat: true,
			},
			{
				text: app.i18n.t("common.yes"),
				color: "error",
				value: true,
				flat: true,
			},
		],
		// buttonTrueText: app.i18n.t("common.yes"),
		// buttonFalseText: app.i18n.t("common.no"),
	})
}
export default (ctx) => {
	app = ctx.app
	init()
}
