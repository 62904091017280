
export default {
	data() {
		return {
			modal: false,
			window: this.$route.hash || "login", // rename window to avoid keyword conflict
			windows: ["login", "register", "send-reset-request", "verify-reset-password"],
			noAuthWindows: ["otp"],
		}
	},
	computed: {
		hash() {
			return this.$route.hash
		},
	},
	mounted() {
		// Listen for hashchange event on window object
		window.addEventListener("hashchange", this.handleHashChange)

		this.$nuxt.$on("auth.show", this.open)
		this.$nuxt.$on("auth.hide", this.close)
		this.$nuxt.$on("auth.success", this.close)

		if (this.hash) {
			this.handleHashChange()
		}
	},
	beforeDestroy() {
		// Don't forget to remove listener when component is destroyed
		window.removeEventListener("hashchange", this.handleHashChange)
	},
	methods: {
		handleHashChange() {
			const hash = window.location.hash.substring(1)
			if (this.windows.includes(hash)) {
				this.open(hash)
			} else {
				this.close()
			}
		},
		close() {
			this.modal = false
			this.$router.push({ hash: undefined }).catch(() => { })
		},
		open(hash = "login") {
			if (this.$auth.loggedIn && !this.noAuthWindows.includes(hash)) {
				this.$router.replace({ hash: undefined }).catch(() => { })
				return
			}

			if (this.$route.hash !== hash) {
				this.$router.push({ hash }).catch(() => { })
			}
			this.window = hash // Use window instead of window property
			this.modal = true
		},
		handleOutsideClick(value) {
			if (value === false) {
				this.close()
			}
		},
	},
}
