import dot from "dot-object"
export const state = () => ({
	token: null,
	code: null,
	userId: null,
	permissions: {},
	preferences: {
		// routeName.componentName.propertyName	: value
		global: {},
	},
	isSaving: false,
	stat: {
		total_deposits: {},
		number_of_withdraw: {},
		applications_count: {},
		number_of_deposits: {},
	},
	isLoggingOut: false,
})

export const getters = {
	token: (state) => {
		return state.token
	},
	code: (state) => {
		return state.code
	},
	userId: (state) => {
		return state.userId
	},
	getPreferencesByRoute: state => (routeName) => {
		return state.preferences[routeName] || {}
	},
	profileStrength: (state) => {
		return state.stat.profile_strength
	},
}
export const mutations = {
	setToken(state, token) {
		state.token = token
	},
	setCode(state, code) {
		state.code = code
	},
	setUserId(state, userId) {
		state.userId = userId
	},

	setPermissions(state, permissions) {
		// convert array to object
		const permissionsAsObject = {}
		permissions.forEach((p) => {
			permissionsAsObject[p] = {}
		})
		const permissionsResult = dot.object(permissionsAsObject)
		state.permissions = permissionsResult
	},
	// setPreferences(state, preferences) {
	// 	state.preferences = preferences;
	// },
	setPreferences(state, preferences) {
		// convert dot notation to object

		const preferencesResult = dot.object(preferences)
		// merge the new preferences with the existing preferences
		state.preferences = { ...state.preferences, ...preferencesResult }
	},
	setIsSaving(state, isSaving) {
		state.isSaving = isSaving
	},
	setStat(state, stat) {
		// merge state.stat with stat deeply withoutloosing any object key
		state.stat = { ...stat }
	},
	updateStat(state, { key, value }) {
		state.stat[key] = value
	},
	setIsLoggingOut(state, isLoggingOut) {
		state.isLoggingOut = isLoggingOut
	},
	updateCode(state, newValue) {
		state.code = newValue
	},
updateUserId(state, newValue) {
		state.userId = newValue
	},
}

export const actions = {
	loadPermissions({ commit }) {
		return this.$axios.$get("/v1/portal/my/permissions").then((response) => {
			this.$gates.setPermissions(response)
			commit("setPermissions", response)
		})
	},
	loadPreferences({ commit }) {
		return this.$axios.$get("/v1/portal/my/preferences").then((response) => {
			commit("setPreferences", response)
		})
	},
	setPreferences({ commit, state }, [key, value]) {
		// take a snapshot of the preferences object in case saving failed
		const preferences = this.$cloneDeep(state.preferences)
		// the key is a dot notation string. it should be merged first to the preferences object
		// first save locally the new value then save as post to the server
		commit("setPreferences", { [key]: value })
		commit("setIsSaving", true)

		return (
			this.$axios
				.$post("/v1/portal/my/preferences", { preferences: state.preferences })
				// .then((resp) => {
				// 	console.log("update-has-been-successful-2");
				// 	this.$toast.success(this.$t("common.update-has-been-successful"));
				// })
				.catch((error) => {
					// if saving failed, revert the preferences to the snapshot
					commit("setPreferences", preferences)
					throw error
				})
				.finally(() => {
					commit("setIsSaving", false)
				})
		)
	},
	getStat({ commit }) {
		return this.$axios.$get("/v1/portal/my/stats").then((response) => {
			commit("setStat", response)
		})
	},
	login({ commit }, { phone, password }) {
		const form = new FormData()
		form.append("email", phone)
		form.append("password", password)
		return this.$auth.loginWith("local", { data: { phone, password }, headers: {} }).then((resp) => {
			this.$auth.setUser(resp.data.user)
			const data = {
				phone: resp.data.user.phone,
				firstName: resp.data.user.firstName,
				lastName: resp.data.user.lastName,
				avatar: resp.data.user.media?.avatar,
				date: new Date(),
			}
			if (this._vm.storage.loginHistory.findIndex(login => this.$isEqual(login.phone, resp.data.user.phone)) === -1) {
				this._vm.storage.loginHistory.push(data)
			} else {
				const index = this._vm.storage.loginHistory.findIndex(login => this.$isEqual(login.phone === resp.data.user.phone))
				this._vm.$set(this._vm.storage.loginHistory, index, data)
			}
		})
	},
	loginWithSocialMedia({ dispatch }, { provider, token }) {
		return this.$auth.loginWith("local", { data: { access_token: token }, url: `/v1/auth/login/${provider}` })
	},
	sendResetPasswordEmail({ commit }, form) {
		return this.$axios.$post("/v1/auth/forgot-password-email", form).then((res) => {
			commit("setToken", res.token)
			commit("setCode", res.code)
			commit("setUserId", res.userId)
			commit('updateCode', res.code)
			commit('updateUserId', res.userId)
		})
	},
	sendResetPasswordPhone({ commit }, form) {
		return this.$axios.$post("/v1/auth/forgot-password-phone", form).then((res) => {
			commit("setCode", res.code)
			commit("setUserId", res.userId)
		})
	},
	verifyCode({ commit }, form) {
		return this.$axios.$post("/v1/auth/verification-code", form)
	},
	verifyToken({ commit }, token) {
		return this.$axios.$get("/v1/auth/verification-token/" + token)
	},
	sendNewPasswordOfToken({ commit }, data) {
		return this.$axios.$post("/v1/auth/reset-password-token/" + data.token, data.form)
	},
	sendNewPasswordOfCode({ commit }, data) {
		return this.$axios.$post("/v1/auth/reset-password-code", data.form)
	},
	sendOtpCode({ commit }, data) {
		return this.$axios.$post("/v1/auth/send-otp-code", data)
	},
	verificationOtpCode({ commit }, data) {
		return this.$axios.$post("/v1/auth/verification-otp-code", data)
	},

	logout({ commit }) {
		commit("setIsLoggingOut", true)
		return this.$auth.logout().finally(() => {
			commit("setIsLoggingOut", false)
		})
	},
	updateCode({ commit }, newValue) {
		commit('updateCode', newValue)
	},
	updateUserId({ commit }, newValue) {
		commit('updateUserId', newValue)
	},

}
