
import field from "../mixins/field";
export default {
	mixins: [field],
	data() {
		return {
			isFocused: false,
			previewModel: false,
		};
	},
	computed: {
		prefix() {
			return this.isFocused || this.localValue ? "https://www.youtube.com/watch?v=" : "";
		},
	},
	methods: {
		onFocus() {
			this.isFocused = true;
		},
		onBlur() {
			this.isFocused = false;
		},
		showPreview() {
			this.previewModel = true;
		},
	},
};
