
import field from "../mixins/field";
import { mdiCalendar } from "@mdi/js";
export default {
	mixins: [field],
	props: {
		noFuture: {
			type: Boolean,
			default: false,
		},

		hours: {
			type: Boolean,
			default: false,
		},
		timeFormat: {
			type: String,
			default: "hh:mm a",
		},
	},
	data() {
		return {
			menu: false,
		};
	},
	computed: {
		localValue: {
			get() {
				if (!this.value) return null;
				return new Date(this.value);
			},
			set(val) {
				this.$emit("input", val?.toISOString());
			},
		},
		localMax() {
			if (this.max) {
				return this.max;
			}

			if (this.noFuture) {
				return new Date().toISOString();
			}

			return undefined;
		},
		calculatedProps() {
			if (typeof this.$attrs !== "object") {
				console.log("this.$attrs", this.$attrs);
				return {};
			}

			return {
				...this.$attrs,
				timeFormat: this.timeFormat,
				label: this.label,
				rules: this.rules,
				textFieldProps: {
					...this.$attrs?.textFieldProps,
					label: this.label,
					appendIcon: mdiCalendar,
					...this.defaultStyle,
					...this.$attrs,
				},
				datePickerProps: {
					...this.$attrs?.datePickerProps,
					max: this.localMax,
				},
				timePickerProps: {
					...this.$attrs?.timePickerProps,
					allowedHours: this.hours ? undefined : (hour) => hour < 12,
				},
			};
		},
	},
};
