
export default {
	name: "SecondaryBar",
	data() {
		return {
			locales: [
				{
					code: "en",
					name: "English",
				},
				{
					code: "ar",
					name: "العربية",
				},
			],
			currencies: [
				{
					code: "JOD",
					name: this.$t("common.jod"),
				},
				{
					code: "USD",
					name: this.$t("common.usd"),
				},
				{
					code: "SAR",
					name: this.$t("common.sar"),
				},
			],
		}
	},

	computed: {
		otherLocale() {
			return this.locales.find(locale => locale.code !== this.$i18n.locale)
		},
		currentCurrency() {
			const currency = this.$store.state.currency
			return this.currencies.find(c => c.code === currency)
		},
	},
	methods: {
		setCurrency(currency) {
			this.$store.commit("setCollectionItem", { key: "currency", item: currency })
			this.$cookies.set("currency", currency)
			window.location.reload()
		},
	},
}
