export function debounce(fn, wait) {
	let timer
	return (...args) => {
		if (timer) {
			clearTimeout(timer) // clear any pre-existing timer
		}
		const context = this // get the current context
		timer = setTimeout(() => {
			fn.apply(context, args) // call the function if time expires
		}, wait)
	}
}

export function throttle(fn, wait) {
	let throttled = false
	return (...args) => {
		if (!throttled) {
			console.log(typeof fn, fn)
			fn.apply(this, args)
			throttled = true
			setTimeout(() => {
				throttled = false
			}, wait)
		}
	}
}
