
export default {
	inheritAttrs: false,
	props: {
		src: {
			type: [String, Array],
			default: null,
		},
		item: {
			type: Object,
			default: () => {
				return {}
			},
		},
		menuProps: {
			type: Object,
			default: () => {
				return {}
			},
		},
	},
	computed: {
		imgURL() {
			return this.item.profile_pic || this.src
		},
	},
}
