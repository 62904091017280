export default (_context, inject) => {
	inject("to", {
		base64(string) {
			return Buffer.from(string).toString("base64")
		},
		boolean(string) {
			return string === "true"
		},
		date(string) {
			return new Date(string)
		},
		float(string) {
			return parseFloat(string)
		},
		integer(string) {
			return parseInt(string)
		},
		number(string) {
			return Number(string)
		},
	})
}
