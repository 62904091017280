export const state = () => ({
	items: [],
})

export const mutations = {
	addItem(state, payload) {
		state.items.push(payload)
	},
	removeItem(state, payload) {
		const index = state.items.findIndex(item => item.productId === payload.productId && item.varianceId === payload.varianceId)
		state.items.splice(index, 1)
	},
	setItems(state, payload) {
		if (!payload) { return }
		state.items = payload
	},
}

export const getters = {
	isAllowedToAdd: state => (categoryId) => {
		// check if the categoryId is already in the state
		if (!state.items.length) { return true }
		return state.items.some((item) => {
			return item.categories.find((category) => {
				return category.categoryId === categoryId
			})
		})
	},
}

export const actions = {
	add({ commit }, payload) {
		console.log("🚀 ~ file: comparison.js:33 ~ add ~ payload:", payload)
		// { productSlug, varianceSlug }
		// fetch the product from api then add it to the state
		return this.$axios.$get(`/v1/products/${payload.productSlug}/${payload.varianceSlug}`).then((resp) => {
			commit("addItem", resp)
		})
	},
	remove({ commit }, payload) {
		// { productSlug, varianceSlug }
		// remove the product from the state by mutations
		commit("removeItem", payload)
	},
	removeAll({ commit }) {
		// remove all the products from the state by mutations
		commit("setItems", [])
	},
}
