
import mime from "mime-type/with-db";
require("css-file-icons/build/css-file-icons.css");
import field from "../mixins/field";
export default {
	mixins: [field],
	props: {
		value: {
			type: [String, Array],
			default: null,
		},
		max: {
			type: Number,
			default: 1,
		},
		types: {
			type: [Array, Boolean],
			default: false,
		},
		rules: {
			type: Array,
			default: () => [],
		},
		label: {
			type: String,
			default: null,
		},
		width: {
			type: [String, Number],
			default: 200,
		},
		aspectRatio: {
			type: [Number],
			default: 1,
		},
		color: {
			type: String,
			default: "rgb(240 240 240)",
		},
	},
	data() {
		return {
			category: false,
			imageUploader: null,
			minimumImageSize: 400,
			files: {},
		};
	},
	computed: {
		internalValue: {
			get() {
				return this.value;
			},
			set(v) {
				// console.trace("set");
				// alert("set");
				this.$emit("input", v);
			},
		},

		remainingAllowed() {
			if (Array.isArray(this.internalValue)) {
				return this.max - this.internalValue.length;
			} else if (typeof this.internalValue === "string") {
				return 0;
			} else {
				return 1;
			}
		},

		allowedFilesText() {
			if (!this.types) {
				return null; // return all files if no types are specified
			}
			// return string after the "/"
			// only {files} types are allowed
			return this.$t("hint.allowed-file-types", { files: this.types.join(", ") });
		},
		allowedFilesMime() {
			if (!this.types) {
				return [];
			}
			return this.types.map((item) => mime.contentType(item));
		},
		isMultiple() {
			return this.max > 1;
		},
	},
	watch: {
		value: {
			handler(now, before) {
				this.sync();
				if (Array.isArray(now) && Array.isArray(before)) {
					// get the deleted items
					const deleted = before.filter((item) => !now.includes(item));
					// delete the deleted items
					deleted.forEach((item) => {
						this.$delete(this.files, item);
					});
				} else if (now !== before) {
					this.$delete(this.files, before);
				}
			},
			immediate: true,
			deep: true,
		},
	},
	mounted() {
		//	this.sync();
	},
	methods: {
		upload() {
			this.$refs.fileInput.click();
		},
		onFileChanged(e) {
			for (let i = 0; e.target.files.length > i; i++) {
				if (e.target.files[i] instanceof File) {
					const ext = e.target.files[i].name.split(".").pop();
					if (this.remainingAllowed) {
						if (!this.types || this.types.includes(ext)) {
							const file = e.target.files[i];

							this.add(file);

							this.files[file.name].uploading = true;
							const formData = new FormData();
							formData.append("file", file);
							this.$axios
								.$post("/v1/upload-media", formData, {
									onUploadProgress: (progressEvent) => {
										const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

										this.files[file.name].uploading = percentCompleted;
									},
								})
								.then((resp) => {
									this.files[file.name].uploading = false;
									if (file.name !== resp.file) this.changeFileName(file.name, resp.file);
								})
								.catch((e) => {
									this.del(file.name);
									this.files[file.name].uploading = false;
									this.genericErrorHandler(e);
								});
						}
					}
				}
			}
			this.loading = false;

			this.$refs.fileInput.value = "";
		},
		changeFileName(oldKey, newKey) {
			if (oldKey === newKey) return;

			if (this.isMultiple) {
				this.internalValue = this.internalValue.map((item) => {
					if (item === oldKey) {
						return newKey;
					}
					return item;
				});
			} else {
				this.internalValue = newKey;
			}
			this.$set(this.files, newKey, this.$cloneDeep(this.files[oldKey]));
			this.files[newKey].name = newKey;
			this.$delete(this.files, oldKey);
		},
		getImageDimensions(file) {
			return new Promise(function (resolve) {
				const i = new Image();
				i.onload = function () {
					resolve({ width: i.width, height: i.height });
				};
				i.src = file;
			});
		},
		del(file) {
			this.$confirm(this.$t("messages.confirm-delete-text"), { title: this.$t("messages.confirm-delete-title") }).then(
				(isAccepted) => {
					if (isAccepted) {
						if (this.isMultiple) {
							this.internalValue = this.internalValue.filter((item) => item !== file);
						} else {
							this.internalValue = null;
						}
						console.log("del", file, this.files);
						this.$delete(this.files, file);
					}
				}
			);
		},
		add(param) {
			let name;
			if (typeof param === "string") {
				name = param;
			} else {
				name = param.name;
			}

			if (this.files[name]) {
				return;
			}

			if (this.isMultiple) {
				if (!Array.isArray(this.internalValue)) {
					this.internalValue = [];
				}
				this.internalValue.push(name);
			} else {
				this.internalValue = name;
			}
			this.createFileObject(param);
		},

		toBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => resolve(reader.result);
				reader.onerror = (error) => reject(error);
			});
		},
		sync() {
			// create files object based on internal value
			if (this.internalValue) {
				if (this.isMultiple) {
					this.internalValue.forEach((file) => {
						this.add(file);
					});
				} else if (typeof this.internalValue === "string") {
					this.add(this.internalValue);
				}
			}
		},
		createFileObject(param) {
			const file = {};
			let isAlreadyUploaded = false;
			const previewTypes = ["jpg", "jpeg", "png", "gif", "svg", "webp"];

			if (typeof param === "string") {
				// extract the filename from url
				isAlreadyUploaded = true;

				file.name = param;
				file.shortName = param.split("/").pop();
			} else {
				file.name = param.name;
				file.shortName = param.name;
			}

			file.ext = file.name.split(".").pop();
			if (previewTypes.includes(file.ext.toLowerCase())) {
				if (isAlreadyUploaded) {
					file.preview = param;
				} else {
					file.preview = URL.createObjectURL(param);
				}
			}

			file.uploading = 0;

			this.$set(this.files, file.name, file);
		},
	},
};
