
import { mapGetters } from "vuex"

export default {
	data() {
		return {
			form: {
				password: "",
				passwordConfirmation: "",
				code: "",
				userId: "",
			},
			isSendingNewPassword: false,
		}
	},

	computed: {
		...mapGetters({
			code: "authExtend/code",
			userId: "authExtend/userId",
		}),
	},

	methods: {
		submit() {
			if (!this.$refs.password.validate()) {
				this.scrollToError()
				return
			}
			this.isSendingNewPassword = true
			this.form.code = this.code
			this.form.userId = this.userId
			this.$store
				.dispatch("authExtend/sendNewPasswordOfCode", { form: this.form })
				.then(() => {
					this.$toast.success(this.$t("auth.password-has-been-updated-successfully"))
					this.$nuxt.$emit("auth.show", "login")
				})
				.catch((e) => {
					this.genericErrorHandler(e, this.$refs.password)
				})
				.finally(() => {
					this.isSendingNewPassword = false
				})
		},
	},
}
